import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSkeletonCardButtom = _resolveComponent("LoadingSkeletonCardButtom")!
  const _component_LoadingSkeletonCardButtomSecondary = _resolveComponent("LoadingSkeletonCardButtomSecondary")!
  const _component_AppCard = _resolveComponent("AppCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading || false)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.primaryNavigation)
            ? (_openBlock(), _createBlock(_component_LoadingSkeletonCardButtom, { key: 0 }))
            : (_openBlock(), _createBlock(_component_LoadingSkeletonCardButtomSecondary, { key: 1 }))
        ], 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.cards, (card) => {
          return (_openBlock(), _createElementBlock("div", {
            key: card.id
          }, [
            _createVNode(_component_AppCard, {
              id: card.id,
              title: card.title,
              img: card.img,
              check: card.selected,
              subtitle: card.subtitle,
              showDetails: card.mostrarDetalle,
              onSelectedMe: _ctx.selectedMe,
              disabled: (_ctx.service.me === 'LocationService' && [22].includes(Number(card.id))) || (_ctx.service.me === 'ActivityService' && card.id === 68)
            }, null, 8, ["id", "title", "img", "check", "subtitle", "showDetails", "onSelectedMe", "disabled"])
          ]))
        }), 128))
  ]))
}