
import { defineComponent, toRefs } from 'vue'
import AppCardHours from '@/components/AppCardHours.vue'
import useCardTurnos from '@/composables/useCardTurnos'
import LoafingSkeletonCardHours from '@/skeletor/LoafingSkeletonCardHours.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    LoafingSkeletonCardHours,
    AppCardHours
  },
  props: {
    horarios: {
      type: Boolean,
      required: false
    },
    dayId: {
      type: Number,
      required: false
    },
    conAccesoVip: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { dayId } = toRefs(props)
    const { cards, cardSelected, currentSelectedCards, error, loading } =
      useCardTurnos(dayId, props.conAccesoVip)

    const { t } = useI18n()
    const selectedMe = (id: number) => {
      cardSelected(id)
      emit('callback', currentSelectedCards.value)
    }
    return {
      error,
      loading,
      cards,
      selectedMe,
      currentSelectedCards,
      t
    }
  }
})
