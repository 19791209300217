import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "fw-bold" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  value: null,
  disabled: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-group", !_ctx.meta.valid ? 'input-error': ''])
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.t(_ctx.label)), 1),
    _withDirectives(_createElementVNode("select", {
      class: "input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      disabled: _ctx.loading
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.t('formularios.provincia.placeholder')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provincias, (p) => {
        return (_openBlock(), _createElementBlock("option", {
          key: p.id,
          value: p.id
        }, _toDisplayString(p.nombre), 9, _hoisted_4))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.val]
    ]),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1)
  ], 2))
}