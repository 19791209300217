
import { ComponentInternalInstance, defineComponent, getCurrentInstance,  watch } from 'vue'
import { defineRule, useField} from 'vee-validate'
import { required, alpha_spaces} from '@vee-validate/rules'
import { useI18n } from 'vue-i18n'
defineRule('alpha_spaces', alpha_spaces)

defineRule('required', required)
export default defineComponent({
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String,
      default: 'formularios.nombreCompleto.label'
    },
    placeholder: {
      type: String,
      default: 'formularios.nombreCompleto.placeholder'
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance;

    const {
      value:field,
      errorMessage,
      meta
    } = useField(
        'input-' + instance.uid.toString(),
      { required: props.required,
        alpha_spaces:true
      },
      {
        initialValue: props.modelValue,
        label: t(props.label).toString().toLowerCase()
      }
    )

    watch(field, val => emit('update:modelValue', val))
    return {
      t,
      field,
      errorMessage,
      meta,
    }
  }
})
