
import { defineComponent, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import FormCarVersion from '@/components/form/FormCarVersion.vue'
import FormPatent from '@/components/form/FormPatent.vue'

export interface CompanionDetailsData {
  nombre: string
}

export default defineComponent({
  components: {
    FormCarVersion,
    FormPatent
  },
  props: {
    vehiculo: {
      type: Object,
      default: () => {
        return {
          modelo: '',
          patente: ''
        }
      }
    },
    modelValue: {
      type: String
    },
    labelName: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()

    const formulario = reactive({
      modelo: '',
      patente: ''
    })

    watch(formulario, (value) => {
      if (value.modelo && value.patente) {
        emit('update:modelValue', value.modelo + ' (' + value.patente + ')')
      } else {
        emit('update:modelValue', '')
      }
      emit('update:vehiculo', {
        modelo: value.modelo,
        patente: value.patente
      })
    })

    return {
      t,
      formulario
    }
  }
})
