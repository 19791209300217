import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-611ba118"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column flex-lg-row h-100" }
const _hoisted_2 = {
  id: "selection-buttons",
  class: "row gx-0 justify-content-center align-content-start ps-4 pe-0 pb-4 bg-bloque-2"
}
const _hoisted_3 = { class: "selection-buttons-title col-12 text-center p-3 pt-4 pb-0 d-flex flex-column align-items-lg-start" }
const _hoisted_4 = {
  id: "detail",
  class: "bg-bloque-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ]),
      _createElementVNode("div", {
        class: "col-12 overflow-auto selection-buttons-button-container g-0",
        style: _normalizeStyle({ '--height': _ctx.height + 'px' })
      }, [
        _renderSlot(_ctx.$slots, "selector", {}, undefined, true)
      ], 4)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ])
  ]))
}