
import { defineComponent, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import router from "@/router";

export default defineComponent({
  props: {
    text: {
      type: String,
      default: 'buttons.continuar'
    },
    urlRedirect: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {

    const { t } = useI18n()
    const { urlRedirect } = toRefs(props)

    const to = () => {
      if ( urlRedirect.value ) {
        router.push(urlRedirect.value)
      }
    }
    return {
      t,
      to
    }
  }
})
