import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default function useLogout() {
  const { commit, state } = useStore<VuexStore>(key)
  const route = useRoute()
  const router = useRouter()
  const toast = useToast()

  const logOut = async () => {
    commit('LOGOUT')
    toast.success('Se cerró correctamente la sesión')
    if (state.navigation.complete) {
      commit('RESET_NAV')
      await router.push({ name: 'Home' })
    } else if (route.name?.toString().startsWith('Solicitudes_')) {
      await router.push({ name: 'Solicitudes_predatos' })
    } else {
      await router.push({ name: 'Home' })
    }
  }

  return {
    logOut
  }
}
