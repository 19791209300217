
import { defineComponent, reactive, ref } from 'vue';
import FormPassword from '@/components/form/FormPassword.vue';
import FormPasswordConfirm from '@/components/form/FormPasswordConfirm.vue';
import router from '@/router';
import { Form, useForm } from 'vee-validate';
import { userService } from '@/services/user.service';
import useLoading from '@/composables/useLoading';
import { useRoute } from 'vue-router';
import { loginService } from '@/services/login.service';
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import { useToast } from 'vue-toastification'

export default defineComponent({
  components: {
    FormPassword,
    FormPasswordConfirm,
    Form
  },
  setup() {
    const { params } = useRoute();
    const { commit, state } = useStore<VuexStore>(key)
    const { loading, setSearch } = useLoading(false);
    const toast = useToast()

    const complete = ref<boolean | string>(false);
    const form = reactive({
      password: '',
      password_confirmation: '',
      token: params?.token,
      email: params?.email ? atob(params.email.toString()) : ''
    });

    const { handleSubmit } = useForm();

    const backClick = () => {
      router.push({ name: 'Home' });
    };

    const submitForm = handleSubmit(async () => {
      try {
        setSearch(true, 'Guardando...');
        await userService.restablecer(form);
        const user = await loginService.ingresar(form.email, form.password_confirmation)
        setSearch(false)
        commit('SET_USER', user)
        toast.success('Iniciaste sesión ' + user.nombre + ' ' + user.apellido)
        await router.push(state.returnUrl ?? { name: 'Home' })
      } catch (e) {
        setSearch(false);
      }
    });

    return {
      backClick,
      submitForm,
      loading,
      form,
      complete
    };
  }
});
