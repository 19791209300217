
import { defineComponent } from 'vue'
import AppResumeComponent from '@/components/AppResumeComponent.vue'
import AppInfoTitles from '@/components/AppInfoTitles.vue'
import AppButtonResetNav from '@/components/buttons/AppButtonResetNav.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'

export default defineComponent({
  components: {
    AppResumeComponent,
    AppInfoTitles,
    AppButtonResetNav
  },
  setup() {
    const { state } = useStore<VuexStore>(key)

    const reservaConfirmada = typeof(state.navigation.datosPersonales.vip.isClient) === 'undefined' || state.navigation.datosPersonales.vip.isClient
    return {
      state,
      reservaConfirmada
    }
  }
})
