import { VuexStore } from '@/interfaces/VuexStore'
import { useStore } from 'vuex'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { key } from '@/setup/store'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export default function useCalendar() {
  const store = useStore<VuexStore>(key)

  const getLinkCalendarGoogle = () => {
    if (
      store.state.navigation?.date &&
      store.state.navigation?.turno &&
      store.state.navigation?.datosPersonales &&
      store.state.navigation?.preDatos?.email &&
      store.state.navigation?.ubicacion &&
      store.state.navigation?.actividad &&
      store.state.navigation?.vehiculo
    ) {
      const fecha = dayjs.utc(store.state.navigation.date.id * 1000)
      const hora = `${fecha.format('YYYYMMDD')} ${
        store.state.navigation.turno.title
      }`
      const datos = dayjs(hora, 'YYYYMMDD HH:mm').add(5, 'hour')
      const detalle = `<b>Nombre y apellido:</b>${store.state.navigation.datosPersonales.personal.nombreApellido}<br/>
        <b>documento:</b>${store.state.navigation.datosPersonales.personal.documento}<br/><br/>
        <b>Email:</b>${store.state.navigation.preDatos.email}
      `

      const calendar = {
        titulo: `${store.state.navigation.ubicacion.title} | ${store.state.navigation.actividad.title} | ${store.state.navigation.vehiculo.title} (${store.state.navigation.vehiculo.subtitle})`,
        descripcion: `${store.state.navigation.ubicacion.title}`,
        horaInicio: `${datos.format('YYYYMMDD[T]HHmm00[Z]')}/${datos
          .add(30, 'minute')
          .format('YYYYMMDD[T]HHmm00[Z]')}`,
        detalle
      }

      return `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${calendar.horaInicio}&details=${calendar.detalle}&location&text=${calendar.titulo}`
    }

    return ''
  }

  return {
    getLinkCalendarGoogle
  }
}
