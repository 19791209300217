
import { VuexStore } from "@/interfaces/VuexStore";
import { key } from "@/setup/store";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { state } = useStore<VuexStore>(key)
      const eventoId = state.evento?.eventoId
    return {
      t,
      eventoId
    };
  },
});
