
import { defineComponent, nextTick, ref } from 'vue'
import AppSideBarComponent from '@/components/AppSideBarComponent.vue'
import AppFooterComponent from '@/components/AppFooterComponent.vue'

export default defineComponent({
  components: {
    AppSideBarComponent,
    AppFooterComponent
  },
  setup() {
    const height = ref(0)
    nextTick(() => {
      height.value =
        document.getElementsByTagName('footer')[0]?.clientHeight ?? 0
    })

    return {
      height
    }
  }
})
