
import { defineComponent, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { key } from '@/setup/store'
import { VuexStore } from '@/interfaces/VuexStore'
import useVoucher from '@/composables/useVoucher'
import router from '@/router'

export default defineComponent({
  setup() {
    const { params } = useRoute()
    const { commit } = useStore<VuexStore>(key)
    const { loading, getVoucher, voucher } = useVoucher()
    const confirmar = async () => {
      if (voucher.value?.codigoReserva) {
        router.push({
          name: 'voucher_cancelar',
          params: { codigoReserva: voucher.value?.codigoReserva }
        })
      }
    }

    watch(
      () => params?.codigoReserva,
      async (codigoReserva) => {
        if (!codigoReserva) {
          return
        }
        await getVoucher(codigoReserva.toString())
      }
    )

    onMounted(() => {
      commit('RESET_NAV')

      getVoucher(params?.codigoReserva.toString())
    })

    return {
      loading,
      voucher,
      confirmar
    }
  }
})
