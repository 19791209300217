import Services from './services.service'
import axios from '@/plugins/axios'
import { InteresSendData } from "@/interfaces/InteresSendData";
import { disableInterceptor } from "@/utils/HeaderNoInterceptors";
import { store } from "@/setup/store";

class PredatosService extends Services {

  sendPredatos(email: string, intereses: InteresSendData): Promise<number> {
    const form = {
      personal: {
        sessionId: store.state.uuid,
        sessionData: JSON.stringify(store.state.track),
        email: email
      },
      intereses: intereses
    }

    const promise = axios.post('front/predatos', form, disableInterceptor)
    return promise.then(res => {
      const { datos } = res.data as { datos: { id: number}}
      store.commit('CLEAR_TRACK')
      return datos.id
    })
  }
}

export const predatosService = new PredatosService()