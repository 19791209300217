import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c261b77"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row justify-content-center text-center mt-5" }
const _hoisted_3 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.logo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.logo,
              alt: "check",
              class: "check"
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "entryButtom", {}, undefined, true)
  ]))
}