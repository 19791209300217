
import { defineComponent, reactive, ref } from 'vue'
import FormName from '@/components/form/FormName.vue'
import FormEmail from '@/components/form/FormEmail.vue'
import router from '@/router'
import { Form, useForm } from 'vee-validate'
import { userService } from '@/services/user.service'
import useLoading from '@/composables/useLoading'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'

export default defineComponent({
  components: {
    FormName,
    FormEmail,
    Form
  },
  setup() {
    const { commit, state } = useStore<VuexStore>(key)
    const { loading, setSearch } = useLoading(false)
    const complete = ref<boolean | string>(false)
    const form = reactive({
      nombre: '',
      apellido: '',
      email: ''
    })

    const { handleSubmit } = useForm()

    const backClick = () => {
      router.go(-1)
    }

    const submitForm = handleSubmit(async () => {
      try {
        setSearch(true, 'Registrando usuario')
        const msj = await userService.registrar(form)
        setSearch(false, '')
        complete.value = msj
        if (state.navigation.complete) {
          commit('RESET_NAV')
        }
      } catch (e) {
        setSearch(false)
      }
    })

    return {
      backClick,
      submitForm,
      loading,
      form,
      complete
    }
  }
})
