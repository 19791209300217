import { ref, onMounted } from 'vue';
import { AutoCompleteI } from '@/interfaces/AutoCompleteI';
import { concesionarioService } from '@/services/concesionario.service';

export default function useProvincesWithDealer() {
  const provincias = ref<AutoCompleteI[]>([])
  const loading = ref(true)

  const getProvincias = async () => {
    provincias.value = await concesionarioService.provinciasAutocompletar()
    loading.value = false
    console.log('Llego a Provincias');
  }

  onMounted(()=>{
      getProvincias()
  })

  return {
    provincias,
    loading,
  }
}

