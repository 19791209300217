
import { defineComponent, ref } from 'vue'
import { Details } from '@/interfaces/CardInterface'

export default defineComponent({
  props: {
    id: {
      type: Number
    },
    title: {
      type: String
    },
    contents: {
      type: Object as () => Details,
      require: false
    },
    check: {
      type: Boolean
    }
  },
  inject: ['routeName'],
  setup(props, { emit }) {
    const id = props.id
    let border = ref(false)
    const logo = require('@/assets/img/icons/no-check.svg')

    const isChecked = () => {
      emit('selectedMe', id)

    }

    return {
      isChecked,
      logo,
      border
    }
  }
})
