import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "btn button_class",
    type: "button",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleBack()), ["prevent"]))
  }, [
    _createVNode(_component_Icon, { icon: ['fas', 'chevron-left'] }),
    _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.text)), 1)
  ]))
}