
import { defineComponent, reactive, watch } from 'vue'
import FormCheck from '@/components/form/FormCheck.vue'
import { useI18n } from 'vue-i18n'

export interface TerminosData {
  imagenDni: string
  imagenLicence: string
  imagenFirma: string
}

export default defineComponent({
  components: {
    FormCheck
  },
  props: {
    modelValue: {
      type: Object as () => TerminosData
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const formulario = reactive({
      receiveInformation: true,
      acceptedTerms: true
    })
    emit('update:modelValue', formulario)

    watch(formulario, value => {
      emit('update:modelValue', value)
    })

    return {
      formulario,
      t
    }
  }
})
