import Services from './services.service'
import axios from '@/plugins/axios'
import { UserLogin } from "@/interfaces/VuexStore";
import { disableInterceptorOk } from '@/utils/HeaderNoInterceptors';

class LoginService extends Services {
  ingresar(email: string, password: string): Promise<UserLogin> {
    const form = {
      email: email,
      password: password
    }

    const login = axios.post('front/ingresar', form, disableInterceptorOk)
    return login.then(res => {
      return res.data
    })
  }
}

export const loginService = new LoginService()
