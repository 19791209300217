import { onMounted, ref } from 'vue'
import { reserveService } from '@/services/reserve.service'
import { useStore } from 'vuex'
import { key } from '@/setup/store'
import { DiasConTurnos } from '@/interfaces/DiasConTurnos'
import useLoading from './useLoading'
import sleep from '@/utils/Sleep'

export default function DaysComposable(conAccesoVip?: boolean | null) {
  const { loading, setSearch } = useLoading(false)
  const error = ref(false)
  const days = ref<DiasConTurnos[]>([])
  const { state } = useStore(key)
  const fetchDay = async () => {
    if (state.navigation.actividad?.id) {
      try {
        error.value = false
        setSearch(true, 'Buscando disponibilidad')
        if (state.navigation.vehiculo?.id) {
          days.value = await reserveService.days(
            state.navigation.actividad.id,
            state.navigation.vehiculo?.id,
            conAccesoVip
          )
        } else {
          days.value = await reserveService.days(state.navigation.actividad.id, undefined, conAccesoVip)
        }
        await sleep(2)
      } catch {
        error.value = true
      } finally {
        setSearch(false, '')
      }
    }
  }

  onMounted(fetchDay)

  return {
    days,
    loading,
    error
  }
}
