
import { defineComponent, reactive } from 'vue'
import { Form, useForm } from 'vee-validate'
import router from '@/router'

import AppInfoTitles from '@/components/AppInfoTitles.vue'
import AppButtonContinue from '@/components/buttons/AppButtonContinue.vue'
import AppButtonReturn from '@/components/buttons/AppButtonReturn.vue'

import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import PersonalDate from '@/views/datos/part/PersonalDate.vue'

import VersionCard from '@/views/datos/part/VersionCard.vue'
import { formulariosService } from '@/services/formularios.service'

import useLoading from '@/composables/useLoading'
import AppDriving from '@/components/AppDriving.vue'

export default defineComponent({
  components: {
    VersionCard,
    PersonalDate,
    Form,
    AppButtonReturn,
    AppButtonContinue,
    AppInfoTitles,
    AppDriving
  },
  setup() {
    const { handleSubmit } = useForm()
    const { t } = useI18n()
    const { state, commit } = useStore<VuexStore>(key)
    const { loading, setSearch } = useLoading(false)
    const form = reactive({
      personal: {},
      vehiculo: {},
      interesadoId: state.navigation.preDatos?.interesadoId
    })
    window.scrollTo(0, 0)

    const onSubmit = handleSubmit(async () => {
      try {
        setSearch(true, 'Estamos procesando tu reserva...')
        const { codigoReserva } = await formulariosService.driving(form)
        await commit('COMPLETE_DATOS_PERSONALES', form)
        await commit('SET_RESERVA', codigoReserva)
        await commit('SEND_FORM_COMPLETE')

        await router.push({
          name: 'Solicitudes_contacto_vehicular_confirmacion'
        })
      } catch (error) {
        setSearch(false, '')
      }
    })

    return {
      onSubmit,
      t,
      form,
      loading
    }
  }
})
