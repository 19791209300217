
import { ComponentInternalInstance, defineComponent, getCurrentInstance, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import useLocalityWithDealer from '@/composables/useLocalityWithDealer'
import { useField } from "vee-validate";

export default defineComponent({
  props: {
    modelValue: {
      type: Number || null,
      requered: true
    },
    label: {
      type: String,
      default: 'formularios.localidad.label'
    },
    provincia: {
      type: Number
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { provincia } = toRefs(props)
    const { localities, loading, getLocalities } = useLocalityWithDealer(provincia)
    const instance = getCurrentInstance() as ComponentInternalInstance;

    const {
      value: val,
      errorMessage,
      meta
    } = useField(
        'input-' + instance.uid.toString(),
        { required: props.required },
        {
          initialValue: props.modelValue,
          label: t(props.label).toString().toLowerCase()
        }
    )

    watch(provincia, async () => {
      await getLocalities()
      emit('update:modelValue', null)
    })

    watch(val, val => {
          emit('update:modelValue', val)
        }
    )

    return {
      t,
      val,
      localities,
      loading,
      errorMessage,
      meta
    }
  }
})
