import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "submit",
    class: "btn button_class",
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.to && _ctx.to(...args)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.t(_ctx.text)) + " ", 1),
    _createVNode(_component_Icon, { icon: ['fas', 'chevron-right'] })
  ], 8, _hoisted_1))
}