import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-list-group" }
const _hoisted_2 = { class: "card-class" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "check"
}
const _hoisted_5 = {
  key: 1,
  class: "card-title-content"
}
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = {
  key: 2,
  class: "card-img-content"
}
const _hoisted_8 = { class: "img-content d-flex align-items-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "fw-bold text-uppercase" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "w-100"
}
const _hoisted_13 = {
  href: "#moreDetails",
  class: "text-decoration-none"
}
const _hoisted_14 = { class: "d-inline fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.isChecked && _ctx.isChecked(...args))),
        class: _normalizeClass(["buttonPrimary", [_ctx.check ? 'active' : '']]),
        disabled: _ctx.disabled
      }, [
        (_ctx.check)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.check)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    class: "icon",
                    icon: ['fas', 'check-circle']
                  }))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.logo,
                alt: _ctx.title
              }, null, 8, _hoisted_6)
            ])),
        (_ctx.img)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("img", {
                  class: "img-class",
                  src: _ctx.img
                }, null, 8, _hoisted_9)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["card-text-content text-start", [_ctx.img ? '' : 'ps-lg-5 pt-lg-0 pt-5']])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["text-content", !_ctx.subtitle ? 'justify-content-center' : 'justify-content-center'])
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("h6", _hoisted_10, _toDisplayString(_ctx.title), 1),
              (_ctx.subtitle)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([[_ctx.img ? '' : 'mb-0'], "fw-bold text-uppercase"]),
                    innerHTML: _ctx.subtitle
                  }, null, 10, _hoisted_11))
                : _createCommentVNode("", true)
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("a", _hoisted_13, [
                    _createVNode(_component_Icon, {
                      class: "text-black me-2",
                      icon: ['fas', 'info-circle']
                    }),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.t(_ctx.detailsInfo)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ], 2)
      ], 10, _hoisted_3)
    ])
  ]))
}