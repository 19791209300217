
import { ComponentInternalInstance, defineComponent, getCurrentInstance, watch } from 'vue'
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String,
      default: 'formularios.provincia.label'
    },
    placeholder: {
      type: String,
      default: 'formularios.provincia.input'
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance() as ComponentInternalInstance;
    const { t } = useI18n()
    const {
      value: field,
      errorMessage,
      meta
    } = useField(
        'input-' + instance.uid.toString(),
      { required: props.required },
      {
        initialValue: props.modelValue,
        label: t(props.label)
      }
    )

    watch(field, val => emit('update:modelValue', val))
    return {
      t,
      field,
      errorMessage,
      meta
    }
  }
})
