import Services from './services.service'
import axios from '@/plugins/axios'
import { disableInterceptor, disableInterceptorOk } from "@/utils/HeaderNoInterceptors";

class UserService extends Services {

  validarEmail(email: string): Promise<string> {
    const login = axios.post<{ mensaje: string }>('front/check-email', { email }, disableInterceptor)
    return login.then(res => {
      return res.data.mensaje
    })
  }
  registrar(form: object): Promise<string> {
    const login = axios.post<{ mensaje: string }>('front/registrar', form, disableInterceptorOk)
    return login.then(res => {
      return res.data.mensaje
    })
  }

  registrarFidelizacion(form: object): Promise<string> {
    const login = axios.post<{ mensaje: string }>('front/registrar-fidelizacion', form, disableInterceptorOk)
    return login.then(res => {
      return res.data.mensaje
    })
  }

  completarRegristro(form: object): Promise<string> {
    const login = axios.post<{ mensaje: string }>('front/activar', form, disableInterceptorOk)
    return login.then(res => {
      return res.data.mensaje
    })
  }

  recuperar(form: object): Promise<string> {
    const login = axios.post<{ mensaje: string }>('front/recuperar', form, disableInterceptorOk)
    return login.then(res => {
      return res.data.mensaje
    })
  }

  restablecer(form: object): Promise<string> {
    const login = axios.post<{ mensaje: string }>('front/reestablecer', form, disableInterceptorOk)
    return login.then(res => {
      return res.data.mensaje
    })
  }

  vehiculosAsociados(): Promise<any[]> {
    const vehiculos = axios.post<any[]>('front/mis-vehiculos', {}, disableInterceptorOk)
    return vehiculos.then(res => {
      return res.data
    })
  }

  agregarVehiculo(form: object): Promise<boolean> {
    const response = axios.post<boolean>('front/mis-vehiculos/agregar', form, disableInterceptorOk)
    return response.then(res => {
      return res.data
    })
  }

  async misCuponPorActividad(id: string): Promise<{ codigo: string, store_url: string }> {

    const { data } = await axios.get<{ codigo: string, store_url: string }>(`front/mis-cupones/${id}`, disableInterceptorOk)
    return data
  }
}

export const userService = new UserService()
