
import { ComponentInternalInstance, defineComponent, getCurrentInstance, watch,ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useField } from "vee-validate";

export default defineComponent({
  props: {
    modelValue: {
      type: Number || null,
      requered: true
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const opciones= ref([
      {
        id: 0,
        nombre: 'Lo antes posible'
      },
      {
        id: 1,
        nombre: 'Una semana'
      },
      {
        id: 2,
        nombre: 'Dos semanas'
      },
      {
        id: 3,
        nombre: 'Tres semanas'
      },
      {
        id: 4,
        nombre: 'cuatro semanas'
      }
    ])
    const instance = getCurrentInstance() as ComponentInternalInstance;

    const {
      value: val,
      errorMessage,
      meta
    } = useField(
        'input-' + instance.uid.toString(),
        { required: props.required },
        {
          initialValue: props.modelValue,
          label: 'momento de contacto'
        }
    )

    watch(val, val => {
          emit('update:modelValue', val)
        }
    )

    return {
      t,
      val,
      errorMessage,
      meta,
      opciones
    }
  }
})
