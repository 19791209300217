
import { defineComponent, ref } from "vue";
import { Details } from "@/interfaces/CardInterface";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    id: {
      type: Number,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    img: {
      type: String,
    },
    contents: {
      type: Object as () => Details,
      require: false,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    check: {
      type: Boolean,
    },
    detailsInfo: {
      type: String,
      default: "card.detalles",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const id = props.id;
    const border = ref(false);
    const logo = require("@/assets/img/icons/no-check.svg");
    const isChecked = () => {
      emit("selectedMe", id);
    };

    return {
      isChecked,
      logo,
      border,
      t,
    };
  },
});
