import { ref, onMounted, Ref } from 'vue';
import { AutoCompleteI } from '@/interfaces/AutoCompleteI';
import { concesionarioService } from '@/services/concesionario.service';

export default function useProvincesWithDealer(provincia: Ref<number|undefined>) {
  const localities = ref<AutoCompleteI[]>([])
  const loading = ref(true)

  const getLocalities = async () => {
    try {
      loading.value = true
      if(!provincia.value){
        return
      }
      localities.value = await concesionarioService.localidadesAutocompletar(provincia.value)
      loading.value = false

    }catch (e){
      console.log('asd')
    }
  }

  onMounted(()=>{
    getLocalities()
  })

  return {
    getLocalities,
    localities,
    loading,
  }
}

