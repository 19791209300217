import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-between" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "col-6 text-end" }
const _hoisted_5 = { class: "row justify-content-between" }
const _hoisted_6 = { class: "col-12 mt-3" }
const _hoisted_7 = { class: "col-12 mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeletor = _resolveComponent("skeletor")!
  const _component_Skeletor = _resolveComponent("Skeletor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h6", _hoisted_3, [
          _createVNode(_component_skeletor, { width: "25%" })
        ]),
        _createElementVNode("h2", null, [
          _createVNode(_component_skeletor, { width: "100%" })
        ]),
        _createElementVNode("h6", null, [
          _createVNode(_component_skeletor, { width: "35%" })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_skeletor, { width: "40%" })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_skeletor, {
          width: "100%",
          height: "200"
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
          return _createVNode(_component_Skeletor, {
            key: i,
            width: "100%"
          })
        }), 64))
      ])
    ])
  ]))
}