import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axiosProd from './axios-prod'
import axiosTest from './axios-test'
import {
  NO_INTERCEPTOR,
  NO_INTERCEPTOR_ERROR,
  NO_INTERCEPTOR_OK
} from '@/utils/HeaderNoInterceptors'
import { useToast } from 'vue-toastification'

let debug = false
const enviroment = process.env.NODE_ENV || 'production'
let instance: AxiosInstance
const toast = useToast();

console.log(enviroment)

switch (enviroment) {
  // case 'development':
  //   // consumo json public/api
  //   // no autentico
  //   // no agrego cabeceras de token
  //   debug = true
  //   instance = axiosDev
  //   break
  case 'development':
    // consumo json public/api
    // no autentico
    // no agrego cabeceras de token
    debug = true
    instance = axiosTest
    break
  default:
    // consumo
    //  autentico
    //  agrego cabeceras de token
    instance = axiosProd
    break
}

instance.interceptors.response.use(respuestaValida, respuestaInvalida)

// Todos los 2**
function respuestaValida(response: AxiosResponse) {
  if (debug) {
    console.log(response.config.headers)
  }

  if (
    response.config.headers[NO_INTERCEPTOR] ||
    response.config.headers[NO_INTERCEPTOR_OK]
  ) {
    return response
  }

  let texto = ''

  switch (response.status) {
    case 200:
      texto = 'Acción ejecutada correctamente'
      break
    default:
      texto = response.statusText
      break
  }

  toast.success(`${response.status.toString()} ${texto}`)
  return response
}

// Todos los 3** 4** 5**
function respuestaInvalida(error: AxiosError) {
  if (debug) {
    console.log(error.response)
  }

  if (typeof error.response === 'undefined') {
    toast.error('Error 503: El servidor no responde, intente nuevamente')
    return Promise.reject(error)
  }



  if (
    error.config.headers[NO_INTERCEPTOR] ||
    error.config.headers[NO_INTERCEPTOR_ERROR]
  ) {
    return Promise.reject(error)
  }

  const response = error.response

  let texto = ''

  if (response.status === 410) {
    return Promise.resolve(response)
  }

  if (response.data?.mensaje) {
    toast.error(`${response.status} ${response.data.mensaje}`)
    return Promise.reject(error)
  }

  switch (response.status) {
    case 400:
      texto = 'Ocurrió un error en la solicitud'
      break
    case 401:
      texto = 'No autorizado'
      break
    case 404:
      texto = 'No existe el recurso solicitado'
      break
    case 410:
      return Promise.resolve(response)
    case 422:
      texto = 'No se pudo procesar la solicitud'
      break
    default:
      texto = response.statusText
      break
  }

  toast.error(`${response.status} ${texto}`)
  return Promise.reject(error)
}

export default instance
