
import { defineComponent } from 'vue'
import router from '@/router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    text: {
      type: String,
      default: 'buttons.loginButton'
    }
  },
  setup() {
    const { t } = useI18n()
    const goLogin = () => {
      router.push('/login')
    }
    return {
      goLogin,
      t
    }
  }
})
