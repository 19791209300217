import { configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import es from '@vee-validate/i18n/dist/locale/es.json'
import en from '@vee-validate/i18n/dist/locale/en.json'
import { setLocale } from '@vee-validate/i18n'
import state from '@/store/state'

configure({
  generateMessage: localize({
    es,
    en
  })
})

setLocale(state.locale)
