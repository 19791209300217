import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LayoutBase from '@/layout/LayoutBase.vue'
import ContentActividades from '@/views/rightBar/ContentActividades.vue'
import ContentUbicaciones from '@/views/rightBar/ContentUbicaciones.vue'
import Home from '@/views/Home.vue'
import { store } from '@/setup/store'
import ContentVehiculo from '@/views/rightBar/ContentVehiculo.vue'
import { locationService } from '@/services/location.service'
import { activityService } from '@/services/activity.service'
import { vehicleService } from '@/services/vehicle.service'
import { resolveHtml } from '@/utils/ResolveHtml'
import { predatosService } from '@/services/predatos.service'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: LayoutBase,
    beforeEnter: async (to, from, next) => {
      try {
        const data = await resolveHtml()
        if (typeof data.vigente !== 'undefined' && !data.vigente) {
          return next({ name: 'Despedida' })
        }

        return next()
      } catch (e) {
        window.location.href = '/error'
      }
    },
    children: [
      {
        path: '/',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/leftBar/Ubicaciones.vue'
          ),
        meta: {
          trackMe: true,
          returnMe: true
        },
        children: [
          {
            path: '',
            name: 'Home',
            component: Home
          },
          {
            path: 'ubicaciones',
            redirect: {
              name: 'Home'
            }
          },
          {
            path: 'ubicaciones/:id',
            redirect: {
              name: 'DetailUbicaciones'
            }
          },
          {
            path: 'ubicaciones/:id/actividades/:actividadId?',
            name: 'DetailUbicaciones',
            component: ContentUbicaciones
          }
        ]
      },
      {
        path: 'ubicaciones/:id/actividades/:actividadId/seleccion/:seleccionId?',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/leftBar/Actividades.vue'
          ),
        meta: {
          trackMe: true,
          returnMe: true
        },
        children: [
          {
            path: '',
            name: 'DetailActividades',
            component: ContentActividades
          }
        ]
      },
      {
        path: 'ubicaciones/:id/actividades/:actividadId/vehiculo',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/leftBar/Vehiculos.vue'
          ),
        meta: {
          trackMe: true,
          returnMe: true
        },
        children: [
          {
            path: '',
            redirect: {
              name: 'Home'
            }
          },
          {
            path: ':vehiculoId',
            name: 'DetailVehiculo',
            component: ContentVehiculo
          },
          {
            path: ':vehiculoId/turnos',
            name: 'DetailActividades_TURNOS',
            component: ContentVehiculo
          }
        ]
      },
      {
        path: 'solicitudes',
        name: 'Solicitudes_predatos',
        meta: {
          trackMe: true
        },
        beforeEnter: async (to, from, next) => {
          if (!store.getters.actualFormularioURL.name) {
            return next({ name: 'Home' })
          }

          if (
            (store.state.navigation.preDatos?.email || store.state.authUser) &&
            store.getters.actualFormularioURL.name
          ) {
            return next(store.getters.actualFormularioURL)
          }

          return next()
        },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/PreDatos.vue')
      },

      {
        path: 'solicitudes/test-drive',
        name: 'Solicitudes_testdrive',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioTestDrive.vue'
          )
      },
      {
        path: 'solicitudes/test-drive/confirmacion',
        name: 'Solicitudes_testdrive_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/TestDrive.vue'
          )
      },

      {
        path: '/solicitudes/academy',
        name: 'Solicitudes_academy',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioAcademy.vue'
          )
      },
      {
        path: '/solicitudes/academy/confirmacion',
        name: 'Solicitudes_academy_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/Academy.vue'
          )
      },

      {
        path: '/solicitudes/academy_fidelizacion',
        name: 'Solicitudes_academy_fidelizacion',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioAcademyFidelizacion.vue'
          )
      },
      {
        path: '/solicitudes/academy_fidelizacion/confirmacion',
        name: 'Solicitudes_academy_fidelizacion_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/AcademyFidelizacion.vue'
          )
      },

      {
        path: '/solicitudes/recital',
        name: 'Solicitudes_recital',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioRecital.vue'
          )
      },
      {
        path: '/solicitudes/recital/confirmacion',
        name: 'Solicitudes_recital_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/Recital.vue'
          )
      },

      {
        path: '/solicitudes/cupo',
        name: 'Solicitudes_cupos_limitados',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioCupoLimitado.vue'
          )
      },
      {
        path: '/solicitudes/cupo/confirmacion',
        name: 'Solicitudes_cupos_limitados_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/CuposLimitador.vue'
          )
      },

      {
        path: '/solicitudes/travesia',
        name: 'Solicitudes_travesia',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioTravesia.vue'
          )
      },
      {
        path: '/solicitudes/travesia/confirmacion',
        name: 'Solicitudes_travesia_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/Travesia.vue'
          )
      },
      {
        path: '/solicitudes/driving',
        name: 'Solicitudes_contacto_vehicular',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioDrivingClinic.vue'
          )
      },
      {
        path: '/solicitudes/driving/confirmacion',
        name: 'Solicitudes_contacto_vehicular_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/DrivingClinic.vue'
          )
      },

      {
        path: '/solicitudes/contacto',
        name: 'Solicitudes_contacto',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioContactDealer.vue'
          )
      },
      {
        path: '/solicitudes/contato/confirmacion',
        name: 'Solicitudes_contacto_confirmacion',
        meta: {
          trackMe: true,
          returnMe: true,
          confirm: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/confirmPages/ContactoDealer.vue'
          )
      },
      {
        path: '/solicitudes/driving',
        name: 'Solicitudes_driving',
        meta: {
          trackMe: true,
          preDatos: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/datos/FormularioDrivingClinic.vue'
          )
      },

      {
        path: '/cookies-policies',
        name: 'Cookies',
        meta: {
          trackMe: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/legalNotices/CookiesPolicies.vue'
          )
      },
      {
        path: '/aviso-legal',
        name: 'LegalWarning',
        meta: {
          trackMe: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/legalNotices/LegalWarning.vue'
          )
      },
      {
        path: '/preguntas-frecuentes',
        name: 'PreguntasFrecuentes',
        meta: {
          trackMe: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/legalNotices/Faq.vue'
          )
      },
      {
        path: '/provacy-policies',
        name: 'ProvacyPolicies',
        meta: {
          trackMe: true,
          returnMe: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/legalNotices/PrivacyPolicies.vue'
          )
      },
      {
        path: '/login',
        name: 'Login',
        meta: {
          trackMe: true
        },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Login.vue')
      },
      {
        path: '/registro',
        name: 'Register',
        beforeEnter: async (to, from, next) => {
          try {
            const data = await resolveHtml()
            if (data.porInvitacion) {
              return next({ name: 'Home' })
            }

            return next()
          } catch (e) {
            window.location.href = '/error'
          }
        },
        meta: {
          trackMe: true,
          loginRequired: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/register/Register.vue'
          )
      },
      {
        path: '/mi-cuenta',
        name: 'MiCuenta',
        meta: {
          loginRequired: true,
          soloFidelizacion: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/MiCuenta.vue'
          )
      },
      {
        path: 'usuario/activacion/:token/:email',
        name: 'Register_activacion',
        meta: {
          trackMe: true
        },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/register/_token.vue')
      },
      {
        path: 'usuario/recuperacion',
        name: 'Recovery',
        meta: {
          trackMe: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/register/Recovery.vue'
          )
      },
      {
        path: '/recuperar/:token/:email',
        name: 'Recovery_token',
        meta: {
          trackMe: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/register/_tokenRecover.vue'
          )
      },

      {
        path: '/voucher/:codigoReserva',
        name: 'voucher_ver',
        meta: {
          returnMe: true
        },
        component: () =>
          import(/* webpackChunkName: "Voucher" */ '../views/voucher/_id.vue')
      },

      {
        path: '/cancelar/:codigoReserva',
        name: 'voucher_cancelar',
        component: () =>
          import(
            /* webpackChunkName: "Voucher" */ '../views/voucher/_cancelar.vue'
          )
      }
    ]
  },

  {
    path: '/error',
    name: 'Error',
    component: () =>
      import(/* webpackChunkName: "NoConfig" */ '../views/NoConfig.vue')
  },

  {
    path: '/despedida',
    name: 'Despedida',
    beforeEnter: async (to, from, next) => {
      try {
        const data = await resolveHtml()

        if (typeof data.vigente !== 'undefined' && data.vigente) {
          return next({ name: 'Home' })
        }
        return next()
      } catch (e) {
        window.location.href = '/error'
      }
    },
    component: () =>
      import(/* webpackChunkName: "NoConfig" */ '../views/Despedida.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    switch (to.name) {
      case 'HOME':
      case 'Cookies':
      case 'LegalWarning':
      case 'ProvacyPolicies':
        return {
          top: 0
        }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  try {

    if (
      typeof to.meta['loginRequired'] !== 'undefined' &&
      (
        (to.meta.loginRequired === true && to.meta?.soloFidelizacion && (!store.getters.isLogin || !store.state.evento?.fidelizacion)) ||
        (to.meta.loginRequired === false && store.getters.isLogin)
      )
    ) {
      return next({ name: 'Home' })
    }

    if (to.meta?.confirm && !store.state.navigation.complete) {
      return next({ name: 'Home' })
    }

    if (!to.meta?.confirm && store.state.navigation.complete) {
      store.commit('CLEAR_FORM_COMPLETE')
    }

    if (!store.state.navigation.ubicacion && to.params?.id) {
      console.error('No hay ubicación, hay que cargarla')
      const ubicacionData = await locationService.listar()
      const selectionUbicacion = ubicacionData.find(
        (card) => card.id === parseInt(to.params?.id.toString())
      )
      store.commit('COMPLETE_UBICACION', selectionUbicacion)
    }

    if (
      store.state.navigation.ubicacion?.id &&
      !store.state.navigation.actividad &&
      to.params?.actividadId
    ) {
      console.error('No hay activiad, hay que cargarla')
      const actividadData = await activityService.listar(
        store.state.navigation.ubicacion.id
      )
      const selectionActividad = actividadData.find(
        (card) => card.id === parseInt(to.params?.actividadId.toString())
      )
      store.commit('COMPLETE_ACTIVIDAD', selectionActividad)
    }

    if (
      store.state.navigation.actividad?.id &&
      !store.state.navigation.vehiculo &&
      (to.params?.seleccionId || to.params?.vehiculoId)
    ) {
      console.error('No hay auto cargado, hay que cargarla')
      const paramsId = to.params?.seleccionId || to.params?.vehiculoId
      const vehiculoData = await vehicleService.listar(
        store.state.navigation.actividad.id
      )
      const selectionVehiculo = vehiculoData.find(
        (card) => card.id === parseInt(paramsId.toString())
      )
      store.commit('COMPLETE_VEHICULO', selectionVehiculo)
    }

    if (to.meta?.trackMe) {
      store.commit('ADD_TRACK', to.fullPath)
    }

    if (to.meta?.returnMe) {
      store.commit('SET_RETURN', to.fullPath)
    }

    const email =
      store.state.authUser?.email || store.state.navigation?.preDatos?.email

    if (to.meta?.preDatos && email) {
      const id = await predatosService.sendPredatos(
        email,
        store.getters.intereses
      )
      store.commit('COMPLETE_PREDATOS', { email: email, interesadoId: id })
    }

    if (
      to.meta?.preDatos &&
      !store.state.navigation?.preDatos &&
      !store.state.authUser
    ) {
      return next({ name: 'Solicitudes_predatos' })
    }

    return next()
  } catch (e) {
    store.commit('RESET_NAV')
    return next({ name: 'Home' })
  }
})

export default router
