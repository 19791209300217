export const NO_INTERCEPTOR = 'X-NO-CAPTURE'
export const NO_INTERCEPTOR_ERROR = 'X-NO-CAPTURE-ERROR'
export const NO_INTERCEPTOR_OK = 'X-NO-CAPTURE-OK'

export const disableInterceptor = {
  headers: {
    [NO_INTERCEPTOR]: 1
  }
}

export const disableInterceptorError = {
  headers: {
    [NO_INTERCEPTOR_ERROR]: 1
  }
}

export const disableInterceptorOk = {
  headers: {
    [NO_INTERCEPTOR_OK]: 1
  }
}
