
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { key } from '@/setup/store'
import { VuexStore } from '@/interfaces/VuexStore'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const { state } = useStore<VuexStore>(key)
    const eventoId = state.evento?.eventoId

    const { t } = useI18n()
    window.scrollTo(0, 0)
    return {
      t,
      eventoId
    }
  }
})
