import { ref, onMounted, Ref } from 'vue';
import { AutoCompleteI } from '@/interfaces/AutoCompleteI';
import { concesionarioService } from '@/services/concesionario.service';

export default function useDealerSelect(localidad: Ref<number | undefined>) {
  const dealers = ref<AutoCompleteI[]>([])
  const loading = ref(true)

  const getDealers = async () => {
    try {
      loading.value = true
      if ( !localidad.value ) {
        return
      }
      dealers.value = await concesionarioService.autocompletarBy(localidad.value)
      loading.value = false
    } catch (e) {
      console.log('Error: ' + e)
    }
  }

  onMounted(() => {
    getDealers()
  })

  return {
    getDealers,
    dealers,
    loading,
  }
}

