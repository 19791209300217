
import { defineComponent, watch } from "vue";
import { defineRule, useField } from "vee-validate";
import { confirmed } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

defineRule("confirmed", confirmed);

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { value: field, errorMessage, meta } = useField(
      "passwordConfirmed",
      { required: props.required, confirmed: "@password" },
      {
        initialValue: props.modelValue,
        label: t("formularios.confirmacionPassword.label").toString().toLowerCase(),
      }
    );

    watch(field, (val) => emit("update:modelValue", val));
    return {
      t,
      field,
      errorMessage,
      meta,
    };
  },
});
