import Services from './services.service'
import axios from '@/plugins/axios'
import { disableInterceptor, disableInterceptorOk } from "@/utils/HeaderNoInterceptors";
import { Voucher } from "@/interfaces/Voucher";

class VoucherService extends Services {
  ver(codigoReserva: string): Promise<Voucher> {
    const login = axios.get<{ datos: Voucher}>('front/voucher/' + codigoReserva, disableInterceptor)
    return login.then(res => {
      return res.data.datos
    })
  }

  cancelar(codigoReserva: string): Promise<Voucher> {
    const login = axios.get<{ datos: Voucher}>('front/voucher/' + codigoReserva + '/cancelar', disableInterceptorOk)
    return login.then(res => {
      return res.data.datos
    })
  }
}

export const voucherService = new VoucherService()
