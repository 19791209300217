
import {
  ComponentInternalInstance,
  defineComponent,
  getCurrentInstance,
  toRefs,
  watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useField } from 'vee-validate'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { modelValue, required } = toRefs(props)
    const instance = getCurrentInstance() as ComponentInternalInstance
    const {
      value: check,
      errorMessage,
      meta
    } = useField(
      'input-' + instance.uid.toString(),
      { required: required.value },
      {
        initialValue: modelValue,
        label: ' '
      }
    )

    watch(check, val => emit('update:modelValue', val))

    return {
      t,
      check,
      errorMessage,
      meta
    }
  }
})
