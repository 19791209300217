const es = {
  derechos: 'Todos los derechos reservados',
  idiomas: {
    veridiomas: 'Cambiar idioma',
    es: 'Español',
    en: 'Ingles'
  },
  agradecimiento: {
    title: 'Gracias',
    aviso: 'Uno de nuestros asesores se pondrá en contacto a la brevedad'
  },
  login: {
    title: 'Iniciar Sesión',
    register: 'Registrarme'
  },
  socialNetworks: {
    facebook: 'Ingresar con Facebook',
    google: 'Ingresar con Google',
    apple: 'Ingresar con Apple'
  },
  buttons: {
    enviar: 'Enviar',
    loginButton: 'Iniciar Sesión',
    volver: 'Volver',
    continuar: 'Continuar',
    ingresar: 'Ingresar',
    image: 'Subir Imagen'
  },
  formDatos: {
    titles: {
      title: 'Completá tus Datos Personales',
      subtitle1:
        '¿ Es la primera vez que ingresa ? Si ya ingresaste con anterioridad podés iniciar sesión con tu email y clave',
      subtitle2:
        'Esta actividad es con turno y requiere que completes tus datos personales para generar un deslinde de responsabilidad digital. Si vas a participar con acompañantes los mismos no pueden ser menores de 12 años',
      subtitle2b: 'Completa tus datos para que podamos contactarte y contarte más en detalle sobre nuestras clínicas de manejo, las proximas fechas disponibles y qué incluye la experiencia',
      subtitle3: 'Complete los datos de sus acompañantes',
      preDatesTitleTurnos: 'Reservá tu turno',
      preDatesSubTitleTurnosNoClientes:
        'Si sos usuario registrado iniciá sesión, sino te invitamos a registrarte o continuá como invitado',
      preDatesSubTitleTurnos:
        'Actividad exclusiva para clientes. Si sos usuario registrado iniciá sesión, caso contrario te invitamos a registrarte para acceder a esta actividad',
      preDatesTitle: 'Dejanos tus Datos',
      preDatesSubTitle:
        'Iniciá sesión o ingresá tu email para que un asesor se comunique con vos',
      preDatosCardTitle: 'Continuá como invitado',
      preDatosCardSubTitle:
        'Si no deseás registrarte podés continuar como invitado ingresando tu email.'
    },
    check: {
      checkbox: 'Deseo actualizar mi usuario con los datos ingresados'
    }
  },
  formularios: {
    password: {
      label: 'Contraseña',
      placeholder: 'Ingresá tu contraseña'
    },
    confirmacionPassword: {
      label: 'Confirmar contraseña',
      placeholder: 'Ingresá de nuevo la contraseña'
    },
    newPassword: {
      label: 'Nueva contraseña',
      placeholder: 'Ingresá la nueva contraseña'
    },
    nombre: {
      label: 'Nombres',
      placeholder: 'Nombre completo'
    },
    apellido: {
      label: 'Apellido',
      placeholder: 'apellido completo'
    },
    email: {
      label: 'Email',
      placeholder: 'Introducir un email'
    },
    telefono: {
      label: 'Teléfono',
      placeholder: 'Teléfono'
    },
    dni: {
      label: 'Documento',
      placeholder: 'Número de documento'
    },
    direccion: {
      label: 'Dirección',
      placeholder: 'Dirección legal'
    },
    nombreCompleto: {
      label: 'Nombre y Apellido',
      placeholder: 'Nombre completo'
    },
    localidad: {
      label: 'Localidad',
      placeholder: 'Seleccioná una localidad',
      input: 'Ingresá una localidad'
    },
    concesionaria: {
      label: 'Concesionaria',
      placeholder: 'Seleccioná una concesionaria'
    },
    provincia: {
      label: 'Provincia',
      placeholder: 'Seleccioná una provincia',
      input: 'Ingresá una provincia'
    },
    nombreAcom: {
      label: 'Nombre Acompañante',
      placeholder: 'Nombre completo'
    },
    nombreAcom1: {
      label: 'Nombre Acompañante 1',
      placeholder: 'Nombre completo'
    },
    nombreAcom2: {
      label: 'Nombre Acompañante 2',
      placeholder: 'Nombre completo'
    },
    nombreAcom3: {
      label: 'Nombre Acompañante 3',
      placeholder: 'Nombre completo'
    },
    edad: {
      label: 'Edad',
      placeholder: 'Edad'
    },
    radioOpcions1: {
      label: 'Deseo crear una cuenta'
    },
    radioOpcions2: {
      label: 'Acepto recibir comunicaciones promocionales via email/teléfono'
    },
    radioOpcions3: {
      label: 'He leído y acepto las políticas de privacidad'
    },
    carVersion: {
      title: 'Modelo de tu vehículo',
      label: 'Modelo de tu vehículo',
      placeholder: 'Ingresá el modelo de tu vehículo'
    },
    adjunto: {
      dni: 'Adjuntá foto de tu DNI',
      licenciaConducir: 'Adjuntá foto de tu Licencia de Conducir'
    },
    patente: {
      label: 'Patente',
      placeholder: 'ingrese su patente'
    }
  },
  resumen: {
    confirmacion: 'su reserva ha sido confirmada!',
    noti: 'Hemos enviado por mail la confirmación de tu reserva y el código para participar de la actividad. Deberás mostrar el mail el día de tu turno.',
    mensaje: '¡Lo esperamos!',
    cancelar: 'Cancelar Turno',
    opinion:
      'Gracias por compartir tu opinión con nosotros , nos ayuda a mejorar',
    aviso: 'Uno de nuestros asesores se pondrá en contacto a la brevedad',
    agradecimiento: '¡Gracias!',
    contactDealer: '¡Gracias por solicitar contacto con un dealer!',
    academy: 'Gracias, su reserva en la ACADEMY ha sido confirmada.',
    testDrive: 'Su turno para realizar el TEST DRIVE ha sido confirmado.',
    travesia: 'Su turno para realizar la TAVESIA ha sido confirmada.'
  },
  card: {
    detalles: 'ver detalles'
  },
  reserve: {
    selectOneDay: 'Seleccione una fecha.',
    errorTurn: 'Error al cargar los turnos.',
    notTurnDisp: 'No se encuentran turnos disponibles.',
    errorDispon: 'Ocurrió un error al traer los turnos disponibles.'
  },
  titulos: {
    seleccioneUn: 'Seleccione un'
  },
  vehiculo: 'Vehículo',
  suVehiculo: 'Vehículo seleccionado',
  restaurarPassword: {
    title: 'Restaurar contraseña'
  },
  titles: {
    actividad: {
      Stitle: 'Selecciona una ',
      act: 'Actividad',
      Stitle2: 'Actividad seleccionada'
    },
    ubicacion: {
      Stitle: '',
      ubi: 'Stand',
      Stitle2: 'Ubicación seleccionada',
      Vubi: 'Ver ubicación',
      bVenido: 'Bienvenido a '
    },
    infoTitles: {
      ubic: 'Ubicación',
      actv: 'Actividad',
      veh: 'Vehículo',
      turn: 'Turno'
    }
  },
  menu: {
    item1: 'Ubicación',
    item2: 'Actividad',
    item3: 'Datos',
    item4: 'Resumen',
    item5: 'Iniciar Sesión',
    item6: 'Cerrar Sesión'
  },
  reserva: {
    diaHorario: 'Elegí un día y horario'
  },
  footer: {
    copyRight: 'Copyright',
    copyRightTexto:
      '- Todos los derechos reservados - Desarrollado por dex360.net',
    avisoLegal: {
      title: 'Aviso Legal'
    },
    polPriv: {
      title: 'Política de Privacidad'
    },
    polCook: 'Políticas de Cookies'
  },
  cookies: {
    Title: 'POLÍTICAS DE COOKIES'
  },
  avisoLegal: {
    Title: 'AVISO LEGAL'
  },
  politicasPrivacidad: {
    Title: 'POLÍTICAS DE PRIVACIDAD'
  },
  firma: {
    ingresar: 'Ingresá tu firma:',
    borrar: 'Borrar firma'
  },
  concesionario: {
    title: 'Encontrá el concesionario más cercano a tu domicilio'
  }
}

export default es
