import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeletor = _resolveComponent("Skeletor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
        return _createVNode(_component_Skeletor, {
          key: i,
          width: "100%",
          height: "120",
          class: "mb-3"
        })
      }), 64))
    ])
  ]))
}