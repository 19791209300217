import { VueApp } from '@/setup/CreateApp'
import { createStore, Store } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { VuexStore } from '@/interfaces/VuexStore'
import { InjectionKey } from 'vue'
import state from '@/store/state'
import mutations from '@/store/mutations'
import actions from '@/store/actions'
import getters from '@/store/getters'

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export const key: InjectionKey<Store<VuexStore>> = Symbol()

export const store = createStore<VuexStore>({
  state,
  mutations,
  actions,
  getters,
  plugins: [vuexLocal.plugin]
})


VueApp.use(store, key)
