import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-content-center my-3" }
const _hoisted_2 = { class: "col-12 alert-info p-4 rounded-3" }
const _hoisted_3 = { class: "text-center fw-bold" }
const _hoisted_4 = {
  key: 0,
  class: "text-center fw-bold mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.t('formDatos.titles.subtitle2b')), 1),
      (_ctx.texto)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.texto), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}