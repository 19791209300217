
import { defineComponent, reactive } from 'vue'
import AppInfoTitles from '@/components/AppInfoTitles.vue'
import AppButtonContinue from '@/components/buttons/AppButtonContinue.vue'
import AppButtonReturn from '@/components/buttons/AppButtonReturn.vue'
import AppDisclaimer from '@/components/AppDisclaimer.vue'

import { useI18n } from 'vue-i18n'
import { Form, useForm } from 'vee-validate'
//import router from '@/router'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import router from '@/router'
import PersonalDate from '@/views/datos/part/PersonalDate.vue'
import CompanionDetails from '@/views/datos/part/CompanionDetails.vue'
import Documentation from '@/views/datos/part/Documentation.vue'
import TermsAndConditions from '@/views/datos/part/TermsAndConditions.vue'
import { formulariosService } from '@/services/formularios.service'
import useLoading from '@/composables/useLoading'

export default defineComponent({
  components: {
    TermsAndConditions,
    Documentation,
    CompanionDetails,
    PersonalDate,
    Form,
    AppInfoTitles,
    AppButtonContinue,
    AppButtonReturn,
    AppDisclaimer
  },
  setup() {
    const { t } = useI18n()
    const { state, commit } = useStore<VuexStore>(key)
    const { loading, setSearch } = useLoading(false)
    window.scrollTo(0, 0)

    const form = reactive({
      personal: {},
      documentacion: {},
      acompanianteUno: {},
      acompanianteDos: {},
      acompanianteTres: {},
      crearCuenta: false,
      terminos: {},
      interesadoId: state.navigation.preDatos?.interesadoId
    })

    const { handleSubmit } = useForm()

    const onSubmit = handleSubmit(async () => {
      try {
        setSearch(true, 'Estamos procesando tu reserva...')
        const { codigoReserva } = await formulariosService.testDrive(form)
        await commit('COMPLETE_DATOS_PERSONALES', form)
        await commit('SET_RESERVA', codigoReserva)
        await commit('SEND_FORM_COMPLETE')

        await router.push({ name: 'Solicitudes_travesia_confirmacion' })
      } catch (error) {
        setSearch(false, '')
      }
    })

    return {
      onSubmit,
      t,
      form,
      loading
    }
  }
})
