import { VuexStore } from '@/interfaces/VuexStore'
import { uuid } from "vue-uuid";

const state: VuexStore = {
  uuid: uuid.v4(),
  locale: 'es',
  navigation: {},
  track: [],
  returnUrl: '/'
}

export default state
