
import {
  ComponentInternalInstance,
  defineComponent,
  getCurrentInstance,
  ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { Field } from 'vee-validate'
import { useToast } from 'vue-toastification'

export default defineComponent({
  components: {
    Field
  },
  props: {
    modelValue: {
      type: String
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'buttons.image'
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const toast = useToast()
    const selectedFile = ref<File>()
    const imgName = ref<string>('')
    const instance = getCurrentInstance() as ComponentInternalInstance

    const inputName = 'input-' + instance.uid.toString()

    const loadFile = (ev: any, handleChange: Function) => {
      const dataUrl = ref<string>('')
      const photo = ev.target.files[0]
      if (!photo.type || !/^image\/png|image\/jpeg/.test(photo.type)) {
        imgName.value = ''
        ev.target.value = ''
        emit('update:modelValue', '')
        handleChange(ev, false)
        toast.error('El formato del archivo no es compatible')
        return
      }

      imgName.value = photo.name
      const originalUrlImg = URL.createObjectURL(photo)
      const img = new Image()
      img.src = originalUrlImg
      img.onload = () => {
        URL.revokeObjectURL(originalUrlImg)
        const c = document.createElement('canvas')
        const ctx = c.getContext('2d')
        const imgW = img.width
        const imgH = img.height

        c.width = 900
        c.height = (imgH * c.width) / imgW
        if (!ctx) {
          return
        }

        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, c.width, c.height)

        dataUrl.value = c.toDataURL('image/jpeg', 0.85)
        emit('update:modelValue', dataUrl.value)
        handleChange(ev)
      }
    }

    return {
      imgName,
      t,
      loadFile,
      selectedFile,
      inputName
    }
  }
})
