
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import { defineComponent, onMounted, ref } from 'vue'

import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const { state, getters } = useStore<VuexStore>(key)
    const texto = ref('')

    onMounted(() => {
      if (getters.tipoActividad === 'contacto_vehicular') {
        texto.value =
          'Actividad exclusiva para realizar con vehículos ' +
          state.evento?.theme.toLocaleUpperCase() +
          ' 4x4'
      }
    })

    return {
      t,
      texto
    }
  }
})
