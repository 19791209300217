const actions = {
  async logout({ commit }: any) {
    try {
      commit('SET_USER', null)
      commit('CLEAR_PREDATOS', null)
    } catch (e) {
      return e
    }
  },

}

export default actions
