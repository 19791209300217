import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "fw-bold" }
const _hoisted_3 = ["placeholder", "required"]
const _hoisted_4 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(!_ctx.meta.valid ? 'input-error': '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.t(_ctx.label)), 1),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        class: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.field) = $event)),
        ref: "password",
        autocomplete: "false",
        placeholder: _ctx.t(_ctx.placeholder),
        required: _ctx.required
      }, null, 8, _hoisted_3), [
        [_vModelText, _ctx.field]
      ])
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1)
  ], 2))
}