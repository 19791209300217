import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86c9c4ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify-content-center mb-3" }
const _hoisted_2 = { class: "row justify-content-around" }
const _hoisted_3 = { class: "col-12 col-md-6 justify-content-start align-items-center align-content-center" }
const _hoisted_4 = {
  key: 0,
  class: "fw-bold mt-2"
}
const _hoisted_5 = {
  key: 1,
  class: "fw-bold mt-2 col-10"
}
const _hoisted_6 = {
  class: "fileUpload text-uppercase btn btn-primary col-12 col-md-3 col-lg-2",
  style: {"width":"auto"}
}
const _hoisted_7 = { class: "me-3" }
const _hoisted_8 = ["id", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      name: _ctx.inputName,
      rules: { required: _ctx.required }
    }, {
      default: _withCtx(({ handleChange, meta }) => [
        _createElementVNode("div", {
          class: _normalizeClass([
          'col-12',
          !meta.valid && meta.touched && _ctx.required
            ? 'border border-2 border-danger input-error'
            : ''
        ])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.imgName)
                ? (_openBlock(), _createElementBlock("h6", _hoisted_4, _toDisplayString(_ctx.t(_ctx.title)), 1))
                : (_openBlock(), _createElementBlock("h6", _hoisted_5, _toDisplayString(_ctx.imgName), 1))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t(_ctx.text)), 1),
              _createVNode(_component_Icon, { icon: ['fas', 'chevron-right'] }),
              _createElementVNode("input", {
                id: _ctx.inputName,
                type: "file",
                accept: "image/png, image/jpeg, image/jpg",
                onInput: ($event: any) => (_ctx.loadFile($event, handleChange)),
                class: "upload"
              }, null, 40, _hoisted_8)
            ])
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["name", "rules"])
  ]))
}