import axios from '@/plugins/axios'
import { store } from '@/setup/store'
import { disableInterceptorOk } from './HeaderNoInterceptors'
// import { EventoState } from '@/interfaces/VuexStore'

export interface Config {
  analyticId: string
  emblema: string
  eventoId: number
  logo: string
  logo_email: string
  nombre: string
  theme: string
  vigente: boolean
  porInvitacion: boolean
}

const loadStyle = (url: string) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  document.getElementsByTagName('head')[0].appendChild(link)
}
const loadTitle = (title: string) => {
  document.title = title
}

export const resolveHtml = async (): Promise<Config> => {
  const { data } = await axios.get<Config>('/eventos/config', disableInterceptorOk)

  if (typeof data.theme === 'undefined') {
    throw 'No config'
  }

  loadStyle('/css/' + data.theme + '/root.css')
  const linkElement = document.querySelector<HTMLLinkElement>('link[rel=icon]')
  if (linkElement) {
    linkElement.href = '/icon/' + data.theme + '/favicon.png'
  }

  if (!data.porInvitacion) {
    data.porInvitacion = false
  }

  store.commit('SET_EVENT', data)
  loadTitle(data.nombre ?? store.state.evento?.nombre)

  return data
}
