import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-lg-6" }
const _hoisted_4 = { class: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCarVersion = _resolveComponent("FormCarVersion")!
  const _component_FormPatent = _resolveComponent("FormPatent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.t('formularios.carVersion.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormCarVersion, {
        modelValue: _ctx.formulario.modelo,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formulario.modelo) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FormPatent, {
        modelValue: _ctx.formulario.patente,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formulario.patente) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}