
import { defineComponent } from 'vue'
import AppSelectCard from '@/components/AppSelectCard.vue'
import { activityService } from '@/services/activity.service'
import ThePageContainer from '@/components/containers/ThePageContainer.vue'
import { useI18n } from 'vue-i18n'
import { CardInterface } from '@/interfaces/CardInterface'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    ThePageContainer,
    AppSelectCard
  },
  provide: {
    routeName: 'DetailActividades'
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { commit, state } = useStore<VuexStore>(key)
    const { t } = useI18n()
    const completeSelection = (data: CardInterface) => {
      router.push({
        name: 'DetailActividades',
        params: {
          id: route.params?.id,
          actividadId: data.id
        }
      })
      commit('COMPLETE_ACTIVIDAD', data)
    }

    return {
      activityService,
      t,
      completeSelection,
      route,
      state
    }
  }
})
