
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'cookies.Title'
    },
    content: {
      type: String,
      default: 'cookies.Content'
    }
  },
  setup() {
    const { t } = useI18n()
    window.scrollTo(0, 0)

    return {
      t
    }
  }
})
