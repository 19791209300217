
import { defineComponent, nextTick, ref } from 'vue'

export default defineComponent({
  setup() {
    const height = ref<number>(0)
    nextTick(() => {
      height.value =
        document.getElementsByClassName('selection-buttons-title')[0]
          ?.clientHeight ?? 0
    })
    return {
      height
    }
  },
})
