import axios from '@/plugins/axios';
import { disableInterceptor } from '@/utils/HeaderNoInterceptors';
import { AutoCompleteI } from '@/interfaces/AutoCompleteI';

class ConcesionarioService {

  async provinciasAutocompletar(): Promise<AutoCompleteI[]> {
    const promesa = axios.get<AutoCompleteI[]>(
      `front/provincias/autocompletar`,
      disableInterceptor
    )
    console.log("se llego aca 'Provincias'");

    return promesa.then(({ data }) => data)
  }

  async localidadesAutocompletar(provinciaId: number): Promise<AutoCompleteI[]> {
    const promesa = axios.get<AutoCompleteI[]>(
      `front/localidades/autocompletar`,
      Object.assign({
        params: { provinciaId: provinciaId }
      }, disableInterceptor),
    )
    console.log("se llego aca 'Localidades'");
    return promesa.then(({ data }) => data)
  }

  async autocompletarBy(localidadId: number): Promise<AutoCompleteI[]> {
    const promesa = axios.get<AutoCompleteI[]>(
      `front/concesionarias/autocompletar`,
      Object.assign({
        params: { localidadId: localidadId }
      }, disableInterceptor),
    )
    console.log(promesa);
    return promesa.then(({ data }) => data)
  }


}

export const concesionarioService = new ConcesionarioService()