import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VPerfectSignature = _resolveComponent("VPerfectSignature")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row", !_ctx.meta.valid ? 'input-error': ''])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["col-12 text-center p-3", (!_ctx.meta.valid && _ctx.meta.touched && _ctx.required)? 'border border-2 border-danger' : ''])
    }, [
      _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.t(_ctx.label)), 1),
      _createVNode(_component_VPerfectSignature, {
        class: "border mx-auto w-100",
        "stroke-options": _ctx.strokeOptions,
        backgroundColor: "#ffffff",
        ref: "signaturePad",
        onOnEnd: _ctx.onEnd
      }, null, 8, ["stroke-options", "onOnEnd"]),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
        class: "btn button_class my-3 mx-auto justify-content-center"
      }, _toDisplayString(_ctx.t("firma.borrar")), 1)
    ], 2)
  ], 2))
}