import axios from "@/plugins/axios"
import { disableInterceptorOk } from "@/utils/HeaderNoInterceptors"

class CuponesService {

  async obtenerCupon(id: string): Promise<{ codigo: string, store_url: string }> {
    const { data } = await axios.post<{ codigo: string, store_url: string }>(`front/obtener-cupon/${id}`, disableInterceptorOk)
    return data
  }

  async hayCuponesDisponibles(id: string): Promise<boolean> {
    const { data } = await axios.get<boolean>(`front/stock-cupones/${id}`, disableInterceptorOk)
    return data
  }
}
export const cuponesService = new CuponesService()
