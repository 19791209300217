
import { defineComponent, ref } from 'vue'
import DaysComposable from '@/composables/Days'
import { DiasConTurnos } from '@/interfaces/DiasConTurnos'
import { useStore } from 'vuex'
import { key } from '@/setup/store'
import { useI18n } from 'vue-i18n'
import { VuexStore } from '@/interfaces/VuexStore'

export default defineComponent({
  props: {
    modelValue: {
      type: Object as () => DiasConTurnos,
      required: true
    },
    conAccesoVip: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { days, loading, error } = DaysComposable(props.conAccesoVip)
    const dayValue = ref()
    const { commit } = useStore(key)
    const { t } = useI18n()
    const { getters } = useStore<VuexStore>(key)

    const onChange = (event: any) => {
      const data = days.value.find(
        (element) =>
          parseInt(element.id.toString()) === parseInt(event.target?.value)
      )
      if (data?.id) {
        commit('CLEAR_TURNO_SOLO')
        emit('update:modelValue', data)
      }
    }

    return {
      days,
      dayValue,
      onChange,
      loading,
      t,
      error,
      getters
    }
  }
})
