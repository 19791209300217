import { VueApp } from '@/setup/CreateApp'
import { createI18n } from 'vue-i18n'
import es from '@/i18n/es'
import en from '@/i18n/en'
import { store } from '@/setup/store'

const messages = {
  en,
  es
}

const i18n = createI18n({
  legacy: false,
  locale: store.state.locale,
  messages
})

VueApp.use(i18n)
