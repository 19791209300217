import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e1d49a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "page",
  id: "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSideBarComponent = _resolveComponent("AppSideBarComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooterComponent = _resolveComponent("AppFooterComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "vh-lg-100",
      style: _normalizeStyle({ 'padding-bottom': _ctx.height + 'px' })
    }, [
      _createVNode(_component_AppSideBarComponent),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ])
    ], 4),
    _createVNode(_component_AppFooterComponent)
  ]))
}