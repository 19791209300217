

import { ComponentInternalInstance, defineComponent, getCurrentInstance, ref } from "vue";
import VPerfectSignature, { StrokeOptions } from "v-perfect-signature";
import { defineRule, useField } from "vee-validate";
import { required } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

defineRule('required', required)

export default defineComponent({
  components: {
    VPerfectSignature,
  },
  props: {
    label: {
      type: String,
      default: 'firma.ingresar'
    },
    modelValue: {
      type: String,
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance() as ComponentInternalInstance;
    const { t } = useI18n();


    const {
      value,
      meta
    } = useField(
        'input-' + instance.uid.toString(),
        { required: props.required },
        {
          initialValue: props.modelValue
        }
    )

    const strokeOptions: StrokeOptions = {
      size: 8,
      thinning: 0.7,
      smoothing: 0,
      streamline: 1
    }

    const signaturePad = ref<typeof VPerfectSignature>()

    const clear = () => {
      value.value = ''
      signaturePad.value?.clear()
    }

    const onEnd = () => {
      const dataUrl = signaturePad.value?.toDataURL('image/jpeg', 0.85)
      value.value = dataUrl
      emit('update:modelValue', dataUrl)
    }

    return {
      signaturePad,
      strokeOptions,
      clear,
      onEnd,
      meta,
      t
    }
  }
})

