import { voucherService } from "@/services/voucher.service";
import { ref } from "vue";
import { Voucher } from "@/interfaces/Voucher";
import sleep from "@/utils/Sleep";
import useLoading from "./useLoading";

export default function useVoucher() {
  const { loading, setSearch } = useLoading(true)
  const voucher = ref<Voucher>()

  const getVoucher = async (codigoReserva: string) => {
    try {
      setSearch(true, 'Buscando reserva...')
      await sleep(2)
      voucher.value = await voucherService.ver(codigoReserva)
      setSearch(true, 'Obteniendo información de la reserva...')
      await sleep(2)
    } catch (e) {
      if (e.response.data) {
        setSearch(true, e.response.data.mensaje)
      } else {
        setSearch(true, 'Error al obtener reserva')
      }
    } finally {
      setSearch(false)
    }
  }

  const cancelVoucher = async (codigoReserva: string) => {
    try {
      setSearch(true, 'Iniciando proceso de cancelacíon de reserva...')
      await sleep(2)
      voucher.value = await voucherService.cancelar(codigoReserva)
      setSearch(true, 'Estamos realizando la cancelación de la reserva...')
      await sleep(1)
      setSearch(true, 'Reserva cancelada...')
      await sleep(1)
    } catch (e: any) {
      if (e.response.data) {
        setSearch(true, e.response.data.mensaje)
      } else {
        setSearch(true, 'Error al obtener reserva')
      }
    } finally {
      setSearch(false)
    }
  }

  return {
    loading,
    getVoucher,
    cancelVoucher,
    voucher
  }
}