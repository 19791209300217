
import { defineComponent, reactive, ref } from "vue";
import FormEmail from "@/components/form/FormEmail.vue";
import router from "@/router";
import { Form, useForm } from "vee-validate";
import { userService } from "@/services/user.service";
import useLoading from "@/composables/useLoading";

export default defineComponent({
  components: {
    Form,
    FormEmail,
  },
  setup() {
    const { loading, setSearch } = useLoading(false);
    const complete = ref<boolean | string>(false);
    const form = reactive({
      email: "",
    });

    const { handleSubmit } = useForm();

    const backClick = () => {
      router.go(-1);
    };

    const submitForm = handleSubmit(async () => {
      try {
        setSearch(true, "Iniciando proceso de recuperación");
        const msj = await userService.recuperar(form);
        setSearch(false, "");
        complete.value = msj;
      } catch (e) {
        setSearch(false);
      }
    });

    return {
      backClick,
      submitForm,
      loading,
      form,
      complete,
    };
  },
});
