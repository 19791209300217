
import { defineComponent } from 'vue'
import AppResumeComponent from '@/components/AppResumeComponent.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import AppButtonResetNav from '@/components/buttons/AppButtonResetNav.vue'

export default defineComponent({
  components: {
    AppButtonResetNav,
    AppResumeComponent
  },
  setup() {
    const { state } = useStore<VuexStore>(key)

    return {
      state
    }
  }
})
