
import { ComponentInternalInstance, defineComponent, getCurrentInstance, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import useProvincesWithDealer from '@/composables/useProvincesWithDealer'
import { useField } from "vee-validate";

export default defineComponent({
  props: {
    modelValue: {
      type: Number || null,
      requered: true
    },
    label: {
      type: String,
      default: 'formularios.provincia.label'
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { loading, provincias } = useProvincesWithDealer()
    const instance = getCurrentInstance() as ComponentInternalInstance;

    const {
      value: val,
      errorMessage,
      meta
    } = useField(
        'input-' + instance.uid.toString(),
        { required: props.required },
        {
          initialValue: props.modelValue,
          label: t(props.label).toString().toLowerCase()
        }
    )

    watch(val, val => {
          emit('update:modelValue', val)
        }
    )

    return {
      t,
      provincias,
      val,
      loading,
      errorMessage,
      meta,
    }
  }
})
