
import { defineComponent, reactive} from 'vue'
import { Form, useForm } from 'vee-validate'
import router from '@/router'

import AppInfoTitles from '@/components/AppInfoTitles.vue'
import AppButtonContinue from '@/components/buttons/AppButtonContinue.vue'
import AppButtonReturn from '@/components/buttons/AppButtonReturn.vue'
import AppDisclaimer from '@/components/AppDisclaimer.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import PersonalDate from '@/views/datos/part/PersonalDate.vue'
import TermsAndConditions from '@/views/datos/part/TermsAndConditions.vue'
import VersionCard from '@/views/datos/part/VersionCard.vue'
import { formulariosService } from '@/services/formularios.service'
import Documentation from '@/views/datos/part/Documentation.vue'
import CompanionDetails from '@/views/datos/part/CompanionDetails.vue'
import useLoading from '@/composables/useLoading'
import VipUser from '@/components/form/VipUser.vue'

export default defineComponent({
  components: {
    CompanionDetails,
    Documentation,
    VersionCard,
    TermsAndConditions,
    PersonalDate,
    Form,
    AppButtonReturn,
    AppButtonContinue,
    AppInfoTitles,
    AppDisclaimer,
    VipUser
  },
  setup() {
    const { handleSubmit } = useForm()
    const { t } = useI18n()
    const { state, commit } = useStore<VuexStore>(key)
    const { loading, setSearch } = useLoading(false);
    const form = reactive({
      vip: {},
      personal: {},
      documentacion: {},
      acompanianteUno: {},
      acompanianteDos: {},
      terminos: {},
      version: '',
      interesadoId: state.navigation.preDatos?.interesadoId
    })
    window.scrollTo(0, 0)

    const onSubmit = handleSubmit(async () => {
      try{
        setSearch(true, 'Estamos procesando tu reserva...');
        const { codigoReserva } = await formulariosService.testDrive(form)
        await commit('COMPLETE_DATOS_PERSONALES', form)
        await commit('SET_RESERVA', codigoReserva)
        await commit('SEND_FORM_COMPLETE')

        await router.push({ name: 'Solicitudes_academy_confirmacion' })

      }catch (error) {
        setSearch(false, '')
      }
    })   
      
      
    return {
      onSubmit,
      t,
      form,
      loading
    }
  }
})
