
import { defineComponent, toRefs, watch } from 'vue'
import AppCard from '@/components/AppCard.vue'
import CardComposable from '@/composables/Card'
import { Listable } from '@/interfaces/Services'
import LoadingSkeletonCardButtom from '@/skeletor/LoadingSkeletonCardButtom.vue'
import LoadingSkeletonCardButtomSecondary from '@/skeletor/LoadingSkeletonCardButtomSecondary.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    LoadingSkeletonCardButtomSecondary,
    LoadingSkeletonCardButtom,
    AppCard
  },
  props: {
    primaryNavigation: {
      type: Boolean,
      default: true
    },
    service: {
      type: Object as () => Listable,
      required: true
    },
    preselect: {
      type: String,
      required: false
    },
    listId: {
      type: Number,
      required: false
    },
    disabled: {
      type: Boolean,
      default: true
    },
  },
  setup(props, { emit }) {
    const { primaryNavigation } = toRefs(props)
    const { preselect, service, listId } = toRefs(props)
    const { error, loading, cards, cardSelected, currentSelectedCards } =
      CardComposable(service.value, preselect, listId)
    const router = useRouter()

    watch(preselect, (id) => {
      if (id) {
        cardSelected(parseInt(id))
        emit('callback', currentSelectedCards.value)
      }else{
        cardSelected()
      }
      
    })

    watch(cards, () => {
      if (cards.value.length > 0) {
        emit('ready', cards.value.length)
      }
    })

    watch(error, (val) => {
      if (val && primaryNavigation.value) {
        router.push({ name: 'Home' })
      }
    })

    const selectedMe = (id: number) => {
      cardSelected(id)
      emit('callback', currentSelectedCards.value)
    }

    return {
      loading,
      cards,
      selectedMe,
      currentSelectedCards,
      error
    }
  }
})
