import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-lg-column align-items-lg-start align-items-center justify-content-center"
}
const _hoisted_2 = { class: "pe-2 d-block m-0 text-muted" }
const _hoisted_3 = { class: "text-uppercase fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSelectCard = _resolveComponent("AppSelectCard")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ThePageContainer = _resolveComponent("ThePageContainer")!

  return (_openBlock(), _createBlock(_component_ThePageContainer, null, {
    title: _withCtx(() => [
      (!_ctx.state.evento?.fidelizacion)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h6", _hoisted_2, " 2. " + _toDisplayString(_ctx.t('titles.actividad.Stitle')), 1),
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('titles.actividad.act')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    selector: _withCtx(() => [
      _createVNode(_component_AppSelectCard, {
        service: _ctx.activityService,
        class: "d-flex justify-content-start justify-content-lg-center flex-row flex-lg-column gx-0 p-0 p-lg-3",
        primaryNavigation: true,
        onCallback: _ctx.completeSelection,
        "list-id": parseInt(_ctx.route.params?.id.toString()),
        preselect: _ctx.route.params?.actividadId
      }, null, 8, ["service", "onCallback", "list-id", "preselect"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}