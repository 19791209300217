
import { defineComponent } from 'vue'
import AppButtonReturn from './buttons/AppButtonReturn.vue'
import AppButtonContinue from './buttons/AppButtonContinue.vue'

export default defineComponent({
  components: { AppButtonReturn, AppButtonContinue },
  props: {
    disabledReturn: {
      type: Boolean,
      default: true
    },
    disabledContinue: {
      type: Boolean,
      default: true
    },
    urlRedirect: {
      type: Object
    },
    goBack: {
      type: Object
    }
  }
})
