import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51795a0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "ol-6 d-flex justify-content-start ps-5" }
const _hoisted_3 = { class: "ol-6 d-flex justify-content-end pe-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButtonReturn = _resolveComponent("AppButtonReturn")!
  const _component_AppButtonContinue = _resolveComponent("AppButtonContinue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.disabledReturn)
        ? (_openBlock(), _createBlock(_component_AppButtonReturn, {
            key: 0,
            goBack: _ctx.goBack,
            class: "buttonFixed position-fixed mb-5 bottom-0"
          }, null, 8, ["goBack"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      ((_ctx.urlRedirect && Object.keys(_ctx.urlRedirect)) && !_ctx.disabledContinue)
        ? (_openBlock(), _createBlock(_component_AppButtonContinue, {
            key: 0,
            disabled: _ctx.disabledContinue,
            urlRedirect: _ctx.urlRedirect,
            class: "buttonFixed position-fixed mb-5 bottom-0"
          }, null, 8, ["disabled", "urlRedirect"]))
        : _createCommentVNode("", true)
    ])
  ]))
}