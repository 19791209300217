import axios from '@/plugins/axios'
import { disableInterceptor } from '@/utils/HeaderNoInterceptors'
import { CardInterface } from '@/interfaces/CardInterface'
import { Listable } from '@/interfaces/Services'
import { VehiculoInterface } from '@/interfaces/VehiculoInterface'
import { TurnoInterface } from '@/interfaces/TurnoInterface'
import { DiasConTurnos } from '@/interfaces/DiasConTurnos'

class VehicleService implements Listable {
  async listar(actividadId: number): Promise<CardInterface[]> {

    const promesa = axios.get<CardInterface[]>(
      'front/actividades/' + actividadId + '/vehiculos',
      disableInterceptor
    )
    return promesa.then(respuesta => {
      return respuesta.data
    })
  }

  async ver(id: number): Promise<VehiculoInterface[]> {
    const promesa = axios.get<VehiculoInterface[]>(
      'front/versiones/' + id,
      disableInterceptor
    )
    return promesa.then(respuesta => {
      return respuesta.data
    })
  }

  async dias(id: number, actividadId: number, locacionId: number): Promise<DiasConTurnos[]> {

    const promesa = axios.get<DiasConTurnos[]>(
      'locaciones/' + locacionId + '/actividades/' + actividadId + '/version/' + id + '/dias',
      disableInterceptor
    )
    return promesa.then(respuesta => {
      return respuesta.data
    })
  }

  async turnos(actividadId: number, locacionId: number, versionId: number, diaId: number): Promise<TurnoInterface[]> {
    const promesa = axios.get<TurnoInterface[]>(
      'locaciones/' + locacionId + '/actividades/' + actividadId + '/version/' + versionId + '/dias/' + diaId,
      disableInterceptor
    )
    return promesa.then(respuesta => {
      return respuesta.data
    })
  }
}

export const vehicleService = new VehicleService()

/*
http://urlAPi/api/{eventoId}/ubicaciones -> ubicaciones de un evento particular
http://urlAPi/api/{ubicacionId}/actividades -> actividades de una ubicaciones particular

http://urlAPi/api/actividades/{actividadID} -> ver
http://urlAPi/api/{actividadId}/autos
http://urlAPi/api/{autoId}/turnos

*/