
import { defineComponent, ref } from "vue";
import FormDate from "@/components/form/FormDate.vue";
import AppSelectCardHours from "@/components/AppSelectCardHours.vue";
import { useI18n } from "vue-i18n";
import { CardInterface } from "@/interfaces/CardInterface";
import { useStore } from "vuex";
import { VuexStore } from "@/interfaces/VuexStore";
import { key } from "@/setup/store";

export default defineComponent({
  components: {
    FormDate,
    AppSelectCardHours,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    conAccesoVip: {
      type: Boolean
    }
  },
  setup() {
    const { t } = useI18n();
    const daySelected = ref({});
    const { commit } = useStore<VuexStore>(key);

    const completeSelection = (data: CardInterface) => {
      console.log(data)
      commit("COMPLETE_TURNO", { turno: data, dia: daySelected.value });
    };
    return {
      t,
      completeSelection,
      daySelected,
    };
  },
});
