import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71be1061"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container pt-4" }
const _hoisted_2 = { class: "row gx-1" }
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormDate = _resolveComponent("FormDate")!
  const _component_AppSelectCardHours = _resolveComponent("AppSelectCardHours")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormDate, {
          modelValue: _ctx.daySelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.daySelected) = $event)),
          "con-acceso-vip": _ctx.conAccesoVip
        }, null, 8, ["modelValue", "con-acceso-vip"])
      ]),
      (_ctx.daySelected.id)
        ? (_openBlock(), _createBlock(_component_AppSelectCardHours, {
            key: 0,
            dayId: _ctx.daySelected.id,
            onCallback: _ctx.completeSelection,
            "con-acceso-vip": _ctx.conAccesoVip
          }, null, 8, ["dayId", "onCallback", "con-acceso-vip"]))
        : _createCommentVNode("", true)
    ])
  ]))
}