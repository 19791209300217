import { onMounted, Ref, ref, watch } from 'vue'
import { CardInterface } from '@/interfaces/CardInterface'
import { Listable } from '@/interfaces/Services'
import sleep from '@/utils/Sleep'

export default function CardComposable(service: Listable, preselected?: Ref<string | undefined>, listableId?: Ref<number | undefined>) {
  const cards = ref<CardInterface[]>([])
  const currentSelectedCards = ref<CardInterface>()
  const error = ref(false)
  const loading = ref(true)

  const fetchCard = async () => {
    try {
      /**
     * Iniciamos  siemrpe el loading que se ejecuta esta
     * funicion, ya que implica carga de datos
     */
      error.value = false
      loading.value = true
      cards.value = []
      await sleep(0.5)
      cards.value = await service.listar(listableId?.value)
      verifySelectedCard()
      if (preselected?.value) {
        cardSelected(parseInt(preselected?.value))
      }
    } catch (err) {
      error.value = true
    } finally {
      /**
       * sacamos el loading, pero siempre al final de loa peticion antes
       */
      loading.value = false
    }
  }

  const cardSelected = (id?: number) => {
    cards.value.map(card => {
      card.selected = card.id === id
      return card
    })
    verifySelectedCard()
  }

  const deselectAllSelected = () => {
    cards.value.map(card => {
      card.selected = false
      return card
    })
    verifySelectedCard()
  }

  const verifySelectedCard = () => {
    cards.value.forEach(card => {
      if (card.selected) {
        currentSelectedCards.value = card
      }
    })
  }

  onMounted(fetchCard)

  if (listableId) {
    watch([listableId],
      async ([val]) => {
        if (val) {
          fetchCard()
        }
      }
    )

  }

  return {
    cards,
    cardSelected,
    currentSelectedCards,
    deselectAllSelected,
    error,
    loading
  }
}