import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "col-12 col-lg-6 text-center" }
const _hoisted_3 = { class: "col-12 col-lg-6 text-center" }
const _hoisted_4 = { class: "form-check-label fw-bold d-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCheck = _resolveComponent("FormCheck")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormCheck, {
        label: "formularios.radioOpcions2.label",
        modelValue: _ctx.formulario.receiveInformation,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formulario.receiveInformation) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormCheck, {
        label: "formularios.radioOpcions3.label",
        modelValue: _ctx.formulario.acceptedTerms,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formulario.acceptedTerms) = $event)),
        required: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("label", _hoisted_4, [
            _createTextVNode(" He leido y acepto las "),
            _createVNode(_component_router_link, {
              tag: "label",
              to: { name: 'ProvacyPolicies' },
              exact: "",
              class: "text-decoration-none form-check-label fw-bold d-inline",
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" politicas de privacidad ")
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}