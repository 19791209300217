
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'


export default defineComponent({
  props: {
    title: {
      type: String,
      required: false
    },
    logo: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    
  },
  setup() {
    const { t } = useI18n()
    return {
      t,
      
      
      
     
    }
  }
})
