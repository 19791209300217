import axios from '@/plugins/axios'
import { disableInterceptor } from '@/utils/HeaderNoInterceptors'
import { CardInterface } from '@/interfaces/CardInterface'
import { locationInterface } from '@/interfaces/LocationInterface'

import { Listable, Ver } from '@/interfaces/Services'

class LocationService implements Listable, Ver {

  me = 'LocationService'

  async listar(): Promise<CardInterface[]> {
    const promesa = axios.get<CardInterface[]>(
      'front/locaciones/listar',
      disableInterceptor
    )
    return promesa.then(respuesta => {
      return respuesta.data
    })
  }
  async ver(id: number): Promise<locationInterface> {
    const promesa = axios.get<locationInterface>(
      'front/locaciones/' + id,
      disableInterceptor
    )
    return promesa.then(respuesta => {
      return respuesta.data
    })
  }
  async actividades(id: number): Promise<CardInterface[]> {
    const promesa = axios.get<CardInterface[]>(
      'locaciones/' + id + '/actividades',
      disableInterceptor
    )
    return promesa.then(respuesta => {
      return respuesta.data
    })
  }


}
export const locationService = new LocationService()
