import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-664b1160"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-fixed bottom-0 w-100" }
const _hoisted_2 = { class: "row gx-0 p-2 text-center" }
const _hoisted_3 = { class: "col-12 col-lg-4 text-lg-start mb-3 mb-lg-0 d-none d-xxl-block" }
const _hoisted_4 = {
  key: 0,
  class: "col-6 my-2 my-lg-0 col-lg d-flex font-size-mobile justify-content-center"
}
const _hoisted_5 = { class: "col-6 my-2 my-lg-0 col-lg d-flex font-size-mobile justify-content-center" }
const _hoisted_6 = { class: "col-6 my-2 my-lg-0 col-lg d-flex font-size-mobile justify-content-center" }
const _hoisted_7 = { class: "col-6 my-2 my-lg-0 col-lg d-flex font-size-mobile justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("footer.copyRight")) + " © " + _toDisplayString(new Date().getFullYear()) + " " + _toDisplayString(_ctx.t("footer.copyRightTexto")), 1),
      (_ctx.eventoId === 11)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              class: "p-0",
              to: { name: 'PreguntasFrecuentes' }
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Preguntas Frecuentes ")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          class: "p-0",
          to: { name: 'LegalWarning' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("footer.avisoLegal.title")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, { to: { name: 'ProvacyPolicies' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("footer.polPriv.title")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, { to: { name: 'Cookies' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("footer.polCook")), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}