
import { defineComponent, reactive} from 'vue'
import { Form, useForm } from 'vee-validate'
import router from '@/router'
import AppButtonContinue from '@/components/buttons/AppButtonContinue.vue'
import AppButtonReturn from '@/components/buttons/AppButtonReturn.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import PersonalDate from '@/views/datos/part/PersonalDate.vue'
import AppInfoTitles from '@/components/AppInfoTitles.vue'
import DealerSelection from '@/views/datos/part/DealerSelection.vue'
import { formulariosService } from '@/services/formularios.service'
import useLoading from '@/composables/useLoading'

export default defineComponent({
  components: {
    DealerSelection,
    AppInfoTitles,
    PersonalDate,
    Form,
    AppButtonContinue,
    AppButtonReturn
  },
  setup() {
    const { commit, state } = useStore<VuexStore>(key)
    const { handleSubmit } = useForm()
    const { loading, setSearch } = useLoading(false);
    window.scrollTo(0, 0)

    const form = reactive({
      personal: {},
      dealerInfo: {},
      interesadoId: state.navigation.preDatos?.interesadoId
    })

    const onSubmit = handleSubmit(async () => {
      try {
        setSearch(true, 'Procesando tu solicitud...');
        await formulariosService.contacto(form)
        await commit('COMPLETE_DATOS_PERSONALES', form)
        await commit('SEND_FORM_COMPLETE')

        await router.push({ name: 'Solicitudes_contacto_confirmacion' })
      } catch (error) {
        setSearch(false, '')
      }
      
    })

    return {
      onSubmit,
      form,
      loading
    }
  }
})
