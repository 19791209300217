import { useStore } from "vuex"
import { VuexStore } from "@/interfaces/VuexStore";
import { key } from "@/setup/store";


export default function useNavigationComplete() {

  const { state } = useStore<VuexStore>(key)

  return {
    navigation: state.navigation
  }
}
