import { Listable } from '@/interfaces/Services'
import axios from '@/plugins/axios'
import { disableInterceptor } from '@/utils/HeaderNoInterceptors'
import { DiasConTurnos } from '@/interfaces/DiasConTurnos'
import { TurnoI } from '@/interfaces/TurnoI'
import { CardInterface } from '@/interfaces/CardInterface'

class ReserveService implements Listable {
  async days(
    actividadId: number,
    versionId?: number,
    conAccesoVip?: boolean | null
  ): Promise<DiasConTurnos[]> {
    let promesa

    const acceso = (conAccesoVip && conAccesoVip !== null) ? '/acceso/' + Number(conAccesoVip) : ''

    if (versionId) {

      promesa = axios.get<DiasConTurnos[]>(
        '/front/versiones/' +
        versionId +
        '/actividades/' +
        actividadId +
        '/dias' + acceso,
        disableInterceptor
      )
    } else {
      promesa = axios.get<DiasConTurnos[]>(
        '/front/actividades/' + actividadId + '/dias' + acceso,
        disableInterceptor
      )
    }
    return promesa.then((respuesta) => {
      return respuesta.data
    })
  }

  async listar(
    atividadId: number,
    dayId: number,
    versionId?: number,
    conAccesoVip?: boolean | null
  ): Promise<CardInterface[]> {
    if (dayId === 0) {
      return []
    }

    console.log(conAccesoVip)
    let promesa
    const acceso = (conAccesoVip && conAccesoVip !== null) ? '/acceso/' + Number(conAccesoVip) : ''

    if (versionId) {
      promesa = axios.get<TurnoI>(
        '/front/versiones/' +
        versionId +
        '/actividades/' +
        atividadId +
        '/dias/' +
        dayId + acceso,
        disableInterceptor
      )
    } else {
      promesa = axios.get<TurnoI>(
        '/front/actividades/' + atividadId + '/dias/' + dayId + acceso,
        disableInterceptor
      )
    }

    return promesa.then((respuesta) => {
      const turnos = respuesta.data?.turnos ?? []
      return turnos.map((turno) => {
        return {
          id: turno.id,
          title: turno.horaTexto,
          vip: turno.vip
        }
      })
    })
  }
}

export const reserveService = new ReserveService()
