import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row g-0" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 alert-warning p-4"
}
const _hoisted_3 = {
  key: 1,
  class: "col-12 alert-warning p-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoafingSkeletonCardHours = _resolveComponent("LoafingSkeletonCardHours")!
  const _component_AppCardHours = _resolveComponent("AppCardHours")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dayId > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_LoafingSkeletonCardHours, { key: 0 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.cards.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.cards, (card) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "col-6 col-lg-12 col-xl-4 col-xxl-3",
                        key: card.id
                      }, [
                        _createVNode(_component_AppCardHours, {
                          id: card.id,
                          title: card.title,
                          img: card.img,
                          check: card.selected,
                          subtitle: card.subtitle,
                          detail: card.mostrarDetalle,
                          onSelectedMe: _ctx.selectedMe
                        }, null, 8, ["id", "title", "img", "check", "subtitle", "detail", "onSelectedMe"])
                      ]))
                    }), 128))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.error)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("h2", null, _toDisplayString(_ctx.t('reserve.errorTurn')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("h2", null, _toDisplayString(_ctx.t('reserve.notTurnDisp')), 1)
                          ]))
                    ], 64))
              ], 64))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}