import { VueApp } from '@/setup/CreateApp'
import VueGtag from "vue-gtag";
import { store } from '@/setup/store'
import router from '@/router';

if(store.state.evento?.analyticId){
  VueApp.use(VueGtag, {
    config: { id: store.state.evento.analyticId }
  }, router);
}
