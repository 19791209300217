
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import useNavigationComplete from '@/composables/useNavigationComplete'

export default defineComponent({
  setup() {
    const { navigation } = useNavigationComplete()
    const { t } = useI18n()

    return {
      t,
      navigation
    }
  }
})
