
import {
  ComponentInternalInstance,
  defineComponent,
  getCurrentInstance,
  watch
} from 'vue'
import { defineRule, useField } from 'vee-validate'
import { required } from '@vee-validate/rules'
import { useI18n } from 'vue-i18n'

defineRule('required', required)
export default defineComponent({
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: true
    },
    fieldName: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance
    const {
      value: field,
      errorMessage,
      meta
    } = useField(
      'input-' + instance.uid.toString(),
      { required: props.required },
      {
        initialValue: props.modelValue,
        label: props.label
      }
    )

    watch(field, val => emit('update:modelValue', val))
    return {
      t,
      field,
      errorMessage,
      meta
    }
  }
})
