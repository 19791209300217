
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import TheAttachedImages from '@/components/TheAttachedImages.vue'
import FormDigitalSignature from '@/components/form/FormDigitalSignature.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import { activityService } from '@/services/activity.service'

export interface DocumentationData {
  imagenDni: string
  imagenLicence: string
  imagenFirma: string
}

export default defineComponent({
  components: {
    FormDigitalSignature,
    TheAttachedImages
  },
  props: {
    modelValue: {
      type: Object as () => DocumentationData
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { state } = useStore<VuexStore>(key)
    const deslindePdf = ref('')
    const error = ref(false)
    const formulario = reactive({
      imagenLicence: '',
      imagenFirma: ''
    })

    emit('update:modelValue', formulario)

    watch(formulario, (value) => {
      emit('update:modelValue', value)
    })

    const resolveDeslinde = async () => {
      if (
        state.navigation?.actividad?.requiereDeslinde &&
        state.navigation?.turno?.id
      ) {
        try {
          error.value = false
          const dataActividad = await activityService.deslinde(
            state.navigation.turno.id
          )
          deslindePdf.value = dataActividad.deslinde
        } catch {
          error.value = true
          deslindePdf.value =
            'Ocurrió un error al obtener el archivo de deslinde, comunique el error a webmaster@dex360.net'
        }
      }
    }

    onMounted(() => {
      resolveDeslinde()
    })
    return {
      t,
      formulario,
      state,
      deslindePdf,
      error
    }
  }
})
