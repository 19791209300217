
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import { defineComponent, onMounted, ref } from 'vue'

import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const { state, getters } = useStore<VuexStore>(key)
    const texto = ref('')

    onMounted(() => {
      if (getters.tipoActividad === 'academy') {
        switch(state.evento?.theme){
          case 'fiat':
            texto.value = 'Actividad exclusiva para realizar con vehículos Fiat Toro 4x4'
            break;
          case 'jeep':
            texto.value = 'Actividad exclusiva para realizar con vehículos Jeep® 4x4'
            break;
        }
      }
    })

    return {
      t,
      texto
    }
  }
})
