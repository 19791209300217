import axios from '@/plugins/axios'
import { disableInterceptor } from '@/utils/HeaderNoInterceptors'
import { CardInterface } from '@/interfaces/CardInterface'
import { Listable } from '@/interfaces/Services'
import { ActivitiesI } from '@/interfaces/ActivitiesI'
import { TurnoI } from '@/interfaces/TurnoI'

class ActivityService implements Listable {

  me = 'ActivityService'
  async listar(loacionId: number): Promise<CardInterface[]> {
    const promesa = axios.get<CardInterface[]>(
      'front/locaciones/' + loacionId + '/actividades/listar',
      disableInterceptor
    )
    return promesa.then((respuesta) => {
      return respuesta.data
    })
  }
  async ver(id: number): Promise<ActivitiesI> {
    const promise = axios.get<ActivitiesI>(
      `front/actividades/${id}`,
      disableInterceptor
    )
    return promise.then(({ data }) => data)
  }

  async vehiculos(actividadId: number): Promise<CardInterface[]> {
    const promise = axios.get<CardInterface[]>(
      `actividades/${actividadId}/vehiculos`,
      disableInterceptor
    )
    return promise.then(({ data }) => data)
  }

  async dias(actividadId: number, locacionId: number): Promise<TurnoI[]> {
    const promise = axios.get<TurnoI[]>(
      `locaciones/${locacionId}/actividades/${actividadId}/dias`
    )
    return promise.then(({ data }) => data)
  }
  async turno(
    diaId: number,
    actividadId: number,
    locacionId: number
  ): Promise<TurnoI[]> {
    const promise = axios.get<TurnoI[]>(
      `locaciones/${locacionId}/actividades/${actividadId}/dias/${diaId}`
    )
    return promise.then(({ data }) => data)
  }

  async deslinde(turnoId: number): Promise<{ deslinde: string }> {
    const promise = axios.get<{ deslinde: string }>(
      `front/deslinde/turno/${turnoId}`,
      disableInterceptor
    )
    return promise.then(({ data }) => data)
  }
}
export const activityService = new ActivityService()

/*
http://urlAPi/api/{eventoId}/ubicaciones -> ubicaciones de un evento particular
http://urlAPi/api/{ubicacionId}/actividades -> actividades de una ubicaciones particular

http://urlAPi/api/actividades/{actividadID} -> ver
http://urlAPi/api/{actividadId}/autos
http://urlAPi/api/{autoId}/turnos

*/
