import { VueApp } from '@/setup/CreateApp'
import './store'
import './LoadRouter'
import './DefineInitialLang'
import './i18n'
import './LoadFonts'
import './Toast'
import './LoadStyle'
import '@/plugins/VeeValidate'
import './Analytics'

VueApp.mount('#app')
