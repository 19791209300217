
import { defineRule, Field } from 'vee-validate'
import {
  ComponentInternalInstance,
  defineComponent,
  getCurrentInstance,
  ref,
  watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { min_value, max_value } from '@vee-validate/rules'
defineRule('min_value', min_value)
defineRule('max_value', max_value)

export default defineComponent({
  components: {
    Field
  },
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String,
      default: 'formularios.edad.label'
    },
    placeholder: {
      type: String,
      default: 'formularios.edad.placeholder'
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance
    const inputName = 'input-' + instance.uid.toString()
    const value = ref('')

    watch(value, val => emit('update:modelValue', val))
    return {
      t,
      value,
      inputName
    }
  }
})
