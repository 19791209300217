
import { defineComponent, onMounted } from "vue";
import AppSelectCard from "@/components/AppSelectCard.vue";
import { vehicleService } from "@/services/vehicle.service";
import ThePageContainer from "@/components/containers/ThePageContainer.vue";
import { useI18n } from "vue-i18n";
import { CardInterface } from "@/interfaces/CardInterface";
import { useStore } from "vuex";
import { VuexStore } from "@/interfaces/VuexStore";
import { key } from "@/setup/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    ThePageContainer,
    AppSelectCard,
  },
  provide: {
    routeName: "DetailActividades",
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { commit, getters} = useStore<VuexStore>(key);
    const { t } = useI18n();

    const completeSelection = (data: CardInterface) => {
      commit("COMPLETE_VEHICULO", data);
      router.push({
        name: String(getters.tipoActividad) === 'contacto' ? 'DetailVehiculo' : "DetailActividades_TURNOS",
        params: {
          id: route.params?.id,
          actividadId: route.params.actividadId,
          vehiculoId: data.id,
        },
      });
    };
    onMounted(() => {
      commit("CLEAR_TURNO");
    });

    return {
      t,
      vehicleService,
      completeSelection,
      route,
    };
  },
});
