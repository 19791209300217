import axios from 'axios'
import { store } from '@/setup/store'

const apiUrl = 'http://localhost:8000/api/v1/'

let option = { baseURL: apiUrl }

const CancelToken = axios.CancelToken
const instance = axios.create(option)

instance.interceptors.request.use(
  function (config) {
    if (typeof store.state.locale !== 'undefined') {
      Object.assign(config.headers, { 'Accept-Language': store.state.locale })
    }

    if (typeof store.state.authUser?.token !== 'undefined') {
      Object.assign(config.headers, {
        Authorization: 'Bearer ' + store.state.authUser.token
      })
    }

    // Creamos un nuevo Source para cada paticion y asi permitir la cancelación
    const source = CancelToken.source()
    Object.assign(config, { cancelToken: source.token })

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default instance
