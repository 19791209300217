
import {
  ComponentInternalInstance,
  defineComponent,
  getCurrentInstance,
  ref,
  watch
} from 'vue'
import { Field } from 'vee-validate'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Field
  },
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String,
      default: 'formularios.dni.label'
    },
    placeholder: {
      type: String,
      default: 'formularios.dni.placeholder'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const instance = getCurrentInstance() as ComponentInternalInstance
    const inputName = 'input-' + instance.uid.toString()
    const value = ref(props.modelValue)

    watch(value, (val) => emit('update:modelValue', val))

    return {
      t,
      value,
      inputName
    }
  }
})
