
import { defineComponent, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { Form, useForm } from 'vee-validate'
import AppInfoTitles from '@/components/AppInfoTitles.vue'
import FormEmail from '@/components/form/FormEmail.vue'
import FormPassword from '@/components/form/FormPassword.vue'
import AppButtonContinue from '@/components/buttons/AppButtonContinue.vue'
import { useStore } from 'vuex'
import { key } from '@/setup/store'
import { VuexStore } from '@/interfaces/VuexStore'
import { useRouter } from 'vue-router'
import { predatosService } from '@/services/predatos.service'
import { loginService } from '@/services/login.service'
import useLoading from '@/composables/useLoading'
import sleep from '@/utils/Sleep'

export default defineComponent({
  components: {
    Form,
    AppInfoTitles,
    FormEmail,
    FormPassword,
    AppButtonContinue
  },
  setup() {
    const { loading, setSearch } = useLoading()
    const { commit, getters } = useStore<VuexStore>(key)
    const router = useRouter()
    const { handleSubmit } = useForm()
    const { t } = useI18n()
    const { state } = useStore<VuexStore>(key)

    const formLogin = reactive({
      email: '',
      password: ''
    })

    const formInvitado = reactive({
      email: ''
    })

    const onSubmitLogin = handleSubmit(async () => {
      try {
        setSearch(true, 'Procesando datos')
        sleep(2)
        const user = await loginService.ingresar(
          formLogin.email,
          formLogin.password
        )
        commit('SET_USER', user)
        await router.push(getters.actualFormularioURL)
      } catch (error) {
        setSearch(false, '')
        await predatosService.sendPredatos(formLogin.email, getters.intereses)
      }
    })

    const onSubmitInvitado = handleSubmit(async () => {
      try {
        setSearch(true, 'Procesando datos')
        commit('COMPLETE_PREDATOS', { email: formInvitado.email })
        sleep(2)
        await router.push(getters.actualFormularioURL)
      } catch (error) {
        console.log(error)
      }
    })

    return {
      onSubmitLogin,
      onSubmitInvitado,
      formLogin,
      formInvitado,
      t,
      loading,
      getters,
      state
    }
  }
})
