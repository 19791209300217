
import { defineComponent, ref } from 'vue'
import { useForm } from 'vee-validate'

import FormEmail from '@/components/form/FormEmail.vue'
import FormPassword from '@/components/form/FormPassword.vue'
import AppButtonReturn from '@/components/buttons/AppButtonReturn.vue'
import AppButtonLogin from '@/components/buttons/AppButtonLogin.vue'
import { useI18n } from 'vue-i18n'
import { loginService } from '@/services/login.service'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import { useRouter } from 'vue-router'
import useLoading from '@/composables/useLoading'
import { useToast } from 'vue-toastification'

export default defineComponent({
  components: {
    FormEmail,
    FormPassword,
    AppButtonLogin,
    AppButtonReturn
  },
  setup() {
    const { loading, setSearch } = useLoading(false)
    const { commit, state } = useStore<VuexStore>(key)
    const email = ref<string>('')
    const password = ref<string>('')
    const { handleSubmit } = useForm()
    const { t } = useI18n()
    const router = useRouter()
    const toast = useToast()

    const onSubmit = handleSubmit(async () => {
      try {
        setSearch(true, 'Iniciando sesión')

        /**
         * HACER LOGIN ---------------------------
         */
        const user = await loginService.ingresar(email.value, password.value)
        commit('SET_USER', user)
        toast.success('Iniciaste sesión ' + user.nombre + ' ' + user.apellido)
        const url = state.returnUrl ?? { name: 'Home' }
        await router.push(url)
      } catch (error) {
        setSearch(false, '')
      }
    })

    return {
      email,
      password,
      onSubmit,
      t,
      loading,
      state
    }
  }
})
