import { StatusBusqueda } from "@/interfaces/StatusBusqueda";
import { ref } from "vue";

export default function useLoading(initStatus: boolean = false) {
  const loading = ref<StatusBusqueda>({
    status: initStatus,
    text: ''
  })

  const setSearch = (status?: boolean, text?: string) => {
    loading.value = {
      status: status ?? loading.value?.status ?? loading.value.status,
      text: text ?? loading.value?.text ?? ''
    }
  }

  return {
    loading,
    setSearch
  }
}
