
import { defineComponent, reactive, watch } from 'vue'
import FormDni from '@/components/form/FormDni.vue'
import { useI18n } from 'vue-i18n'
import FormNameCompanion from '@/components/form/FormNameCompanion.vue'
import FormAge from '@/components/form/FormAge.vue'
import FormDigitalSignature from '@/components/form/FormDigitalSignature.vue'

export interface CompanionDetailsData {
  nombreApellido: string
  documento: number
  edad: number
}

export default defineComponent({
  components: {
    FormNameCompanion,
    FormDni,
    FormAge,
    FormDigitalSignature
  },
  props: {
    modelValue: {
      type: Object as () => CompanionDetailsData
    },
    labelName: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()

    const formulario = reactive({
      nombreApellido: '',
      documento: null,
      edad: null,
      imagenFirma: ''
    })
    emit('update:modelValue', formulario)

    watch(formulario, (value) => {
      emit('update:modelValue', value)
    })

    return {
      t,
      formulario
    }
  }
})
