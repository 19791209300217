import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48881425"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check-label fw-bold d-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-check p-2", 
      !_ctx.meta.valid && _ctx.meta.touched && _ctx.required
        ? 'border border-2 border-danger'
        : ''
    ])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.t(_ctx.label)), 1)
    ], true),
    _withDirectives(_createElementVNode("input", {
      class: "form-check-input checkboxChecked float-none ms-2",
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.check) = $event))
    }, null, 512), [
      [_vModelCheckbox, _ctx.check]
    ])
  ], 2))
}