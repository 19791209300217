import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "m-1" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = { class: "fw-bold text-uppercase mb-0" }
const _hoisted_4 = {
  key: 0,
  class: "check col-4"
}
const _hoisted_5 = {
  key: 1,
  class: "col-4"
}
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.isChecked && _ctx.isChecked(...args))),
      class: _normalizeClass(["g-0 py-2 row w-100 rounded align-items-center button-hours", [_ctx.check ? 'active' : 'border-0']])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ]),
      (_ctx.check)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.check)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  class: "icon",
                  icon: ['fas', 'check-circle']
                }))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.logo,
              alt: _ctx.title
            }, null, 8, _hoisted_6)
          ]))
    ], 2)
  ]))
}