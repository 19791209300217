
import { defineComponent } from 'vue'
import AppResumeComponent from '@/components/AppResumeComponent.vue'
import AppInfoTitles from '@/components/AppInfoTitles.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import AppButtonResetNav from '@/components/buttons/AppButtonResetNav.vue'
import useCalendar from '@/composables/useCalendar'

export default defineComponent({
  components: {
    AppResumeComponent,
    AppInfoTitles,
    AppButtonResetNav
  },
  setup() {
    const { state } = useStore<VuexStore>(key)
    const { getLinkCalendarGoogle } = useCalendar()

    return {
      state,
      getLinkCalendarGoogle
    }
  }
})
