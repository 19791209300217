import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "btn m-auto",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetNav()))
  }, [
    _createTextVNode(" Volver a empezar "),
    _createVNode(_component_Icon, { icon: ['fas', 'chevron-right'] })
  ]))
}