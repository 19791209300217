const en = {
  derechos: "All rights reserved",
  idiomas: {
    veridiomas: "Change language",
    es: "Spanish",
    en: "English",
  },
  agradecimiento: {
    title: "Thank you",
    aviso: "One of our advisors will contact you shortly",
  },
  login: {
    title: "Login Form",
    register: "Register",
  },
  socialNetworks: {
    facebook: "Sign in with Facebook",
    google: "Sign in with Google",
    apple: "Sign in with Apple",
  },
  buttons: {
    enviar: 'Submit',
    loginButton: 'Log in',
    volver: 'Return',
    continuar: 'Continue',
    ingresar: 'Get into',
    image: 'Upload Image',
  },
  formDatos: {
    titles: {
      title: 'Fill in your Personal Data',
      subtitle1: 'Is it the first time you log in? If you have already entered before you can log in with your email and password',
      subtitle2: 'This activity is in turn and requires you to complete your personal data to generate a digital disclaimer',
      subtitle2b: 'Complete your information so that we can contact you and tell you more in detail about our driving clinics, the next available dates and what the experience includes',
      subtitle3: 'Fill in the details of your companions',
      preDatesTitleTurnos: 'Book your turn',
      preDatesSubTitleTurnos: 'Login or enter your email to continue with your reservation',
      preDatesTitle: 'Leave us your data',
      preDatesSubTitle: 'Log in or enter your email so that an advisor can contact you',
      preDatosCardTitle: 'Continue as guest',
      preDatosCardSubTitle: 'If you do not want to register you can continue as your reservation as a guest by entering your email.',
    }
  },
  formularios: {
    password: {
      label: "Password",
      placeholder: "Enter  password",
    },
    confirmacionPassword: {
      label: "Confirm Password",
      placeholder: "Enter the password again",
    },
    newPassword: {
      label: "New Password",
      placeholder: "Enter the new password",
    },
    nombre: {
      label: "Names",
      placeholder: "Full name",
    },
    apellido: {
      label: 'last name',
      placeholder: 'Full last name'
    },
    email: {
      label: "Email",
      placeholder: " Enter an email",
    },
    telefono: {
      label: "Phone",
      placeholder: "Phone",
    },
    dni: {
      label: "DNI",
      placeholder: "Enter your document",
    },
    direccion: {
      label: "Address",
      placeholder: "Enter your address",
    },
    nombreCompleto: {
      label: "Full name",
      placeholder: "Enter your full name",
    },
    localidad: {
      label: "Location",
      placeholder: "Select a location",
      input: "Enter a location"
    },
    concesionaria: {
      label: "Concessionaire",
      placeholder: 'Enter a concessionaire'
    },
    provincia: {
      label: "Province",
      placeholder: "Select a province",
      input: "Enter a province"
    },
    nombreAcom: {
      label: "Companion Name",
      placeholder: "Enter the name of the first companion",
    },
    nombreAcom1: {
      label: "Companion Name 1",
      placeholder: "Enter the name of the first companion",
    },
    nombreAcom2: {
      label: "Companion Name 2",
      placeholder: "Enter the name of the second companion",
    },
    edad: {
      label: "Age",
      placeholder: "Enter your age",
    },
    radioOpcions1: {
      label: "I want to create an account",
    },
    radioOpcions2: {
      label: "I agree to receive communications via email / phone",
    },
    radioOpcions3: {
      label: "I have read and accept the privacy policies",
    },
    carVersion: {
      title: "Tell us the model of your vehicle",
      label: "Vehicle model",
      placeholder: "Enter your vehicle model",
    },
    adjunto: {
      dni: "Attach a photo of your ID",
      licenciaConducir: "Attach photo of your Drivers License",
    },
    patente: {
      label: 'Patent',
      placeholder: 'Enter you patent'
    },
  },
  resumen: {
    confirmacion: 'your reservation has been confirmed!',
    noti: 'We have sent by email the confirmation of your reservation and the code to participate in the activity. You must show the email on the day of your shift.',
    mensaje: '¡We look forward to it!',
    cancelar: 'Cancel Shift',
    opinion: 'Thank you for sharing your opinion with us, it helps us to improve',
    aviso: 'One of our advisors will contact you shortly',
    agradecimiento: '¡Thanks!',
    contactDealer: '¡Thank you for requesting contact with a dealer!',
    academy: 'Thank you, your reservation at the academy has been confirmed.',
    testDrive: 'Thank you, your reservation at the test drive has been confirmed.',
    travesia: 'Thank you, your reservation at the trvesia has been confirmed.'
  },
  card: {
    detalles: "see details",
  },
  reserve: {
    selectOneDay: "Select a date.",
    errorTurn: "Error loading shifts.",
    notTurnDisp: "No shifts available.",
    errorDispon: 'An error occurred while fetching available shifts.'
  },
  titulos: {
    seleccioneUn: "Select a",
  },
  vehiculo: "Vehicle",
  suVehiculo: "Selected Vehicle",
  restaurarPassword: {
    title: "Restore Password",
  },
  titles: {
    actividad: {
      Stitle: "Select one",
      act: "Activity",
      Stitle2: "Selected activity",
    },
    ubicacion: {
      Stitle: "Select one",
      ubi: "Location",
      Stitle2: "Selected Location",
      Vubi: "See location",
      bVenido: "Welcome to",
    },
    infoTitles: {
      ubic: "Location",
      actv: "Activity",
      veh: "Vehicle",
      turn: "Turn",
    },
  },
  menu: {
    item1: "Location",
    item2: "Activity",
    item3: "Data",
    item4: "Abstract",
    item5: " Log In",
    item6: " Log Out",
  },
  reserva: {
    diaHorario: "Choose a day and time",
  },
  footer: {
    copyRight: "Copyright",
    copyRightTexto: '- All rights reserved - Developed by dex360.net',
    avisoLegal: {
      title: "Legal Warning",
    },
    polPriv: {
      title: "Privacy Policy",
    },
    polCook: "Cookie Policies",
  },
  cookies: {
    Title: "POLÍTICAS DE COOKIES",
  },
  firma: {
    ingresar: 'Enter your signature:',
    borrar: 'Delete signature'
  },
  concesionario: {
    title: 'Find the dealer closest to your home'
  }

};

export default en;
