
import { defineComponent, onMounted, ref, watch } from 'vue'
import LoadingSkeletonDetail from '@/skeletor/LoadingSkeletonDetail.vue'
import { useRoute } from 'vue-router'
import TheReserveDay from '@/views/detail/TheReserveDay.vue'
import { useI18n } from 'vue-i18n'
import AppContentButtonsComponent from '@/components/AppContentButtonsComponent.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import useNavigationComplete from '@/composables/useNavigationComplete'
import DetailSeccion from '@/views/detail/DetailSeccion.vue'
import { vehicleService } from '@/services/vehicle.service'

export default defineComponent({
  components: {
    DetailSeccion,
    AppContentButtonsComponent,
    TheReserveDay,
    LoadingSkeletonDetail
  },
  setup() {
    const { navigation } = useNavigationComplete()
    const { getters, state } = useStore<VuexStore>(key)
    const { t } = useI18n()
    const route = useRoute()
    const loading = ref(true)
    const data = ref({})
    const error = ref(false)

    const resolveRequest = async (idRoute: number) => {
      try {
        error.value = false
        loading.value = true
        const dataVehiculo = await vehicleService.ver(idRoute)
        data.value = dataVehiculo
        loading.value = false
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      await resolveRequest(parseInt(route.params.vehiculoId.toString()))
    })

    watch(
      () => route.params.vehiculoId,
      async (idRoute) => {
        console.log('cambio')
        if (!idRoute) {
          return
        }
        await resolveRequest(parseInt(idRoute.toString()))
      }
    )

    return {
      t,
      loading,
      getters,
      state,
      navigation,
      data,
      error
    }
  }
})
