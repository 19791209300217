
import { defineComponent } from 'vue'
import router from '@/router'
import { useI18n } from 'vue-i18n'
import { useStore } from "vuex";
import { VuexStore } from "@/interfaces/VuexStore";
import { key } from "@/setup/store";

export default defineComponent({

  setup() {
    const { commit } = useStore<VuexStore>(key)
    const { t } = useI18n()
    const resetNav = async () => {
      commit("RESET_NAV")
      await router.push({ name: 'Home' })
    }
    return {
      resetNav,
      t
    }
  }
})
