import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mt-4 fw-normal" }
const _hoisted_2 = { class: "col-md-6 col-lg-3" }
const _hoisted_3 = { class: "col-md-6 col-lg-3" }
const _hoisted_4 = {
  key: 0,
  class: "col-md-6 col-lg-3"
}
const _hoisted_5 = {
  key: 1,
  class: "col-md-6 col-lg-3"
}
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "col-md-4" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = {
  key: 3,
  class: "col-12 text-center alert alert-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormName = _resolveComponent("FormName")!
  const _component_FormEmail = _resolveComponent("FormEmail")!
  const _component_FormTel = _resolveComponent("FormTel")!
  const _component_FormDni = _resolveComponent("FormDni")!
  const _component_FormAddress = _resolveComponent("FormAddress")!
  const _component_FormProvinceText = _resolveComponent("FormProvinceText")!
  const _component_FormLocationText = _resolveComponent("FormLocationText")!
  const _component_FormCheck = _resolveComponent("FormCheck")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row gy-3 mb-3", { 'justify-content-center py-5': _ctx.simplificado}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["col-12", { 'text-center': _ctx.simplificado}])
    }, [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.t('formDatos.titles.title')), 1)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormName, {
        required: !_ctx.disablePreLoading.nombreApellido,
        disabled: _ctx.disablePreLoading.nombreApellido,
        modelValue: _ctx.formulario.nombreApellido,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formulario.nombreApellido) = $event))
      }, null, 8, ["required", "disabled", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormEmail, {
        required: !_ctx.disablePreLoading.nombreApellido,
        disabled: _ctx.disablePreLoading.email,
        modelValue: _ctx.formulario.email,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formulario.email) = $event))
      }, null, 8, ["required", "disabled", "modelValue"])
    ]),
    (!_ctx.simplificado)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_FormTel, {
            modelValue: _ctx.formulario.telefono,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formulario.telefono) = $event)),
            required: !_ctx.indispensables
          }, null, 8, ["modelValue", "required"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.simplificado)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_FormDni, {
            disabled: _ctx.disablePreLoading.documento,
            required: _ctx.getters.tipoActividad === 'contacto' ? false : !_ctx.disablePreLoading.documento,
            modelValue: _ctx.formulario.documento,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formulario.documento) = $event))
          }, null, 8, ["disabled", "required", "modelValue"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.simplificado && _ctx.getters.tipoActividad !== 'contacto')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FormAddress, {
              modelValue: _ctx.formulario.direccion,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formulario.direccion) = $event)),
              required: !_ctx.indispensables
            }, null, 8, ["modelValue", "required"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FormProvinceText, {
              modelValue: _ctx.formulario.provincia,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formulario.provincia) = $event)),
              required: !_ctx.indispensables
            }, null, 8, ["modelValue", "required"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_FormLocationText, {
              modelValue: _ctx.formulario.localidad,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formulario.localidad) = $event)),
              required: !_ctx.indispensables
            }, null, 8, ["modelValue", "required"])
          ])
        ], 64))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_FormCheck, {
            label: "formularios.radioOpcions1.label",
            checked: ""
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}