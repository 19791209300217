import { onMounted, Ref, ref, watch } from 'vue'
import { CardInterface } from '@/interfaces/CardInterface'
import { reserveService } from "@/services/reserve.service";
import { useStore } from "vuex";
import { key } from "@/setup/store";

export default function useCardTurnos(dayId: Ref<number | undefined>, conAccesoVip?: boolean | null) {

  const cards = ref<CardInterface[]>([])
  const currentSelectedCards = ref<CardInterface>()
  const error = ref(false)
  const loading = ref(true)
  const { state } = useStore(key)

  const fetchCard = async () => {
    try {
      /**
       * Iniciamos  siemrpe el loading que se ejecuta esta
       * funicion, ya que implica carga de datos
       */
      error.value = false
      loading.value = true
      cards.value = []
      if (state.navigation.actividad?.id && dayId.value) {
        cards.value = await reserveService.listar(state.navigation.actividad.id, dayId.value, state.navigation.vehiculo?.id, conAccesoVip)
      }
    } catch (err) {
      error.value = true
    } finally {
      /**
       * sacamos el loading, pero siempre al final de loa peticion antes
       */
      loading.value = false
    }
  }

  const cardSelected = (id: number) => {
    cards.value.map(card => {
      card.selected = card.id === id
      return card
    })
    verifySelectedCard()
  }

  const deselectAllSelected = () => {
    cards.value.map(card => {
      card.selected = false
      return card
    })
    verifySelectedCard()
  }

  const verifySelectedCard = () => {
    cards.value.forEach(card => {
      if (card.selected) {
        currentSelectedCards.value = card
      }
    })
  }

  onMounted(fetchCard)

  watch(dayId, async () => {
    await fetchCard()
  })

  return {
    cards,
    cardSelected,
    currentSelectedCards,
    deselectAllSelected,
    error,
    loading
  }
}