import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "fw-bold" }
const _hoisted_3 = ["placeholder", "disabled"]
const _hoisted_4 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    name: _ctx.inputName,
    label: _ctx.t(_ctx.label).toString().toLowerCase(),
    rules: { required: !_ctx.disabled, min_value: '12', max_value: 118 }
  }, {
    default: _withCtx(({ field, errorMessage }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.t(_ctx.label)), 1),
        _createElementVNode("input", _mergeProps(field, {
          autocomplete: "false",
          type: "number",
          placeholder: _ctx.t(_ctx.placeholder),
          disabled: _ctx.disabled,
          class: "input"
        }), null, 16, _hoisted_3)
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(errorMessage), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "name", "label", "rules"]))
}