import axios from '@/plugins/axios'
import { disableInterceptor } from '@/utils/HeaderNoInterceptors'
import { Ver } from '@/interfaces/Services'
import { EventoInterface } from '@/interfaces/EventoInterface'

export interface Despedida {
  emblema: string
  eventoId: number
  logo: string
  logo_email: string
  mensaje: string
  nombre: string
  theme: string
}
class EventoService implements Ver {
  async ver(): Promise<EventoInterface[]> {
    const promesa = axios.get<EventoInterface[]>(
      'front/eventos',
      disableInterceptor
    )
    return promesa.then((respuesta) => {
      return respuesta.data
    })
  }

  async despedida(): Promise<Despedida> {
    const promesa = axios.get<Despedida>(
      'eventos/despedida',
      disableInterceptor
    )
    return promesa.then((respuesta) => {
      return respuesta.data
    })
  }
}
export const eventoService = new EventoService()
