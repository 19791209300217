
import { VuexStore } from '@/interfaces/VuexStore'
import { eventoService } from '@/services/evento.service'
import { key } from '@/setup/store'
import { defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const { state } = useStore<VuexStore>(key)
    const mensaje = ref('');
    
    document.title = state.evento?.nombre || 'Verano 2024'

    onMounted(async ()=>{
      const { mensaje: mensajeRes }= await eventoService.despedida()
      mensaje.value = mensajeRes
    })

    return {
      logo: state.evento?.logo,
      mensaje
    }
  }
})
