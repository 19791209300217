
import { defineComponent, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { key } from '@/setup/store';
import { VuexStore } from '@/interfaces/VuexStore';
import useVoucher from '@/composables/useVoucher';

export default defineComponent({
  setup() {
    const { params } = useRoute();
    const { commit } = useStore<VuexStore>(key);
    const { loading, cancelVoucher, voucher } = useVoucher();

    watch(
      () => params?.codigoReserva,
      async (codigoReserva) => {
        if (!codigoReserva) {
          return;
        }
        await cancelVoucher(codigoReserva.toString());
      }
    );

    onMounted(() => {
      commit('RESET_NAV');
      cancelVoucher(params?.codigoReserva.toString());
    });

    return {
      loading,
      voucher
    };
  }
});
